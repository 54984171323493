import { crudRequest } from '../apiClient';
import { methods, PATHs } from '..';
import { parseError } from '../../utils';

const getUser = id => {
  const { userDetail } = PATHs;

  const params = {
    filter: {
      fields: ['id', 'name', 'email', 'image', 'lastName'],
      include: ['projects']
    }
  };
  const uri = userDetail(id);

  return crudRequest({ uri, method: methods.GET, params }).catch(err => {
    parseError({ err, noFetch: 'crud.user.detail.error' });
    throw err;
  });
};

export { getUser };
