import React from 'react';
import styles from './Home.module.scss';
import homeImg from '../../assets/img/avalua.png';
import logoImg from '../../assets/img/brand/logo.png';
import { literal } from '../../core/utils';

export default function Home() {
  return (
    <div className={styles.container}>
      <img src={logoImg} className={styles.logo} alt="" />
      <div className={styles.title}>{literal('general.slogan1')}</div>
      <div className={styles.subtitle}>{literal('general.slogan2')}</div>
      <a href="http://www.avalua.eu/" target="_blank">
        <img src={homeImg} className={styles.sublogo} alt="" />
      </a>
    </div>
  );
}
