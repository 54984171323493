import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import classnames from 'classnames';
import { history, literal } from '../../../core/utils';
import styles from './Tabs.module.scss';

const Tabs = props => {
  const { items, initialIndex = '1', goBack = true, title } = props;
  const [activeTab, setActiveTab] = useState();

  useEffect(() => setActiveTab(initialIndex), [initialIndex]);
  useEffect(() => {
    if (props.forcedIndex) {
      setActiveTab(props.forcedIndex);
      props.setForcedIndex(null);
    }
  }, [props.forcedIndex]);

  return (
    <Fragment>
      <div className={styles.fragmentContainer}>
        <Row>
          <Col md={12} className={styles.pageTitleContainer}>
            <span className={styles.pageTitle}>{title}</span>
            <div className={'d-flex justify-content-end align-items-center'}>
              {props.headerButtons || null}
              {goBack && (
                <Button
                  color="primary"
                  size="sm"
                  className={`${styles.pageTitleButton} ml-3`}
                  onClick={() => history.goBack()}
                >
                  {literal('general.goBack')}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={styles.colContainer}>
            <div className={styles.tabContainer}>
              <div className={styles.navContainer}>
                <Nav tabs>
                  {items.map((tab, index) => (
                    <NavItem key={`navItem_${index}`}>
                      <NavLink
                        className={classnames({
                          active: activeTab === parse(index),
                        })}
                        onClick={() => {
                          setActiveTab(parse(index));
                        }}
                        disabled={tab.disabled}
                      >
                        {tab.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
              <TabContent activeTab={activeTab} className={styles.tabContent}>
                {items.map((tab, index) => (
                  <TabPane key={`tabPane${index}`} tabId={parse(index)}>
                    {tab.component}
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );

  function parse(idx) {
    return `${idx + 1}`;
  }
};

export default Tabs;
