import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';

import { literal } from '../../../core/utils';
import { projectsAPI, usersAPI } from '../../../core/requests';
import { Loading } from '..';

const ModalRelation = props => {
  const { data, onSave, toggle, isSearchable = true } = props;
  const {
    title = literal('general.manage'),
    model,
    placeholder = literal('general.select'),
    type,
    isMulti = true
  } = data;

  const defaultItems =
    model && model.length
      ? model.map(({ id, name }) => ({ value: id, label: name }))
      : [];

  const [selected, setSelected] = useState(defaultItems);
  const [options, setOptions] = useState();
  useEffect(loadOptions, []);

  const clickSave = () => {
    if (type === 'projects') {
      let projects = [];
      if (selected) {
        if (isMulti) projects = selected.map(project => project.value);
        else projects = [selected.value];
      }

      usersAPI
        .userProjects({ id: data.id, projects: { projects } })
        .then(() => {
          onSave();
          toggle();
        });
    } else {
      toggle();
    }
  };

  const handleChange = selectedOption => {
    setSelected(selectedOption);
  };

  return options ? (
    <Modal isOpen={true}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <Select
          value={selected}
          closeMenuOnSelect={!isMulti}
          options={options}
          onChange={handleChange}
          isMulti={isMulti}
          isSearchable={isSearchable}
          placeholder={placeholder}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="default" onClick={toggle}>
          {literal('general.close')}
        </Button>
        <Button color="primary" onClick={clickSave}>
          {literal('general.save')}
        </Button>
      </ModalFooter>
    </Modal>
  ) : (
    <Loading />
  );

  function loadOptions() {
    if (type === 'projects') {
      projectsAPI.getProjects({}).then(elements => {
        const res =
          elements &&
          elements.map(({ id, name }) => ({ value: id, label: name }));

        setOptions(res);
      });
    }
  }
};

export default ModalRelation;
