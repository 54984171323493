import { Redirect } from 'react-router';
import ControlCenter from './components/Devices/ControlCenter';
import Users from './components/Users/List/UsersList';
import User from './components/Users/Detail/User';
import Projects from './components/Projects/Wrapper/Projects';
import Audiospooks from './components/Audiospooks/Wrapper/Audiospooks';
import Project from './components/Projects/Detail/Project';
import Audiospook from './components/Audiospooks/Detail/Audiospook';
import React from 'react';
import Home from './components/Home/Home';
import { literal } from './core/utils/localize';

const LoginPath = '/login';
const ForgotPath = '/forgot';
const DashboardPath = '/dashboard';
const UsersPath = '/users';
const UserDetailPath = `${UsersPath}/:id`;
const UserProjectsPath = `${UsersPath}/:id/projects`;
const ProjectsPath = '/projects';
const ProjectsDetailPath = `${ProjectsPath}/:id`;
const SpooksPath = '/simso';
const SpookDetailPath = `${SpooksPath}/:id`;
const UserSpooksPath = `${UsersPath}/:id/simso`;
const HomePath = '/home';
const DevicesPath = '/devices';

const UserDetailUri = id => `${UsersPath}/${id}`;
const UserProjectsUri = id => `${UsersPath}/${id}${ProjectsPath}`;
const UserSpooksUri = id => `${UsersPath}/${id}${SpooksPath}`;
const ProjectDetailUri = id => `${ProjectsPath}/${id}`;
const AudioSpookDetailUri = id => `${SpooksPath}/${id}`;
const DefaultUri = () => {
  return <Redirect to={HomePath} />;
};
const routes = [
  {
    path: DevicesPath,
    exact: true,
    name: literal('breadcrumb.devices'),
    component: ControlCenter,
  },
  {
    path: UsersPath,
    exact: true,
    name: literal('breadcrumb.users'),
    component: Users,
    admin: true,
  },
  {
    path: ProjectsPath,
    exact: true,
    name: literal('breadcrumb.projects'),
    component: Projects,
  },
  {
    path: ProjectsDetailPath,
    exact: true,
    name: literal('breadcrumb.project'),
    component: Project,
  },
  {
    path: UserDetailPath,
    exact: true,
    name: literal('breadcrumb.user'),
    admin: true,
    component: User,
  },
  {
    path: UserProjectsPath,
    exact: true,
    name: literal('breadcrumb.projects'),
    component: Projects,
  },
  {
    path: SpooksPath,
    exact: true,
    name: literal('breadcrumb.simsos'),
    component: Audiospooks,
  },
  {
    path: SpookDetailPath,
    exact: true,
    name: literal('breadcrumb.simso'),
    component: Audiospook,
  },
  {
    path: UserSpooksPath,
    exact: true,
    name: literal('breadcrumb.simsos'),
    component: Audiospooks,
  },
  {
    path: HomePath,
    exact: true,
    name: literal('breadcrumb.home'),
    component: Home,
  },
  { component: DefaultUri },
];

export {
  routes,
  ForgotPath,
  LoginPath,
  DashboardPath,
  UsersPath,
  UserDetailPath,
  UserDetailUri,
  UserProjectsPath,
  ProjectsPath,
  ProjectsDetailPath,
  ProjectDetailUri,
  UserProjectsUri,
  SpooksPath,
  UserSpooksPath,
  UserSpooksUri,
  SpookDetailPath,
  AudioSpookDetailUri,
  DevicesPath,
  HomePath,
};
