import { request } from '../apiClient';
import { methods, PATHs } from '..';
import { literal, toastUtils } from '../../utils';

const forgotPassword = data => {
  const { forgotPassword } = PATHs;
  return request(forgotPassword, methods.POST, data).catch(err => {
    toastUtils.notify(err ? err.message : literal('session.forgot.error'));
  });
};

const setPassword = data => {
  const { setPassword } = PATHs;
  const { firstPwd, token, userId } = data;
  return request(
    setPassword,
    methods.POST,
    {
      userId,
      newPassword: firstPwd
    },
    token
  ).catch(err => {
    toastUtils.notify(err ? err.message : literal('session.identity.error'));
  });
};

export { forgotPassword, setPassword };
