import { request } from '../apiClient';
import { methods, PATHs } from '..';
import { saveUserToken, storage, history } from '../../utils';

const login = (params, redirect = '/projects') => {
  const { login } = PATHs;
  return request(login, methods.POST, params)
    .then(token => {
      let res;
      if (token && token.id) {
        saveUserToken(token.id, params.rememberMe);
        if (token.user) {
          storage.setPersistentItem('user', token.user);
          if (token.user.language) {
            storage.setPersistentItem('simso-language', token.user.language);
          }
        }
        history.push(redirect);
        res = Promise.resolve(token);
      } else Promise.reject();
      return res;
    })
    .catch(err => Promise.reject(err));
};

export { login };
