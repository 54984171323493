import { request } from '../apiClient';
import { methods, PATHs } from '..';

const updateUser = data => {
  const { usersList } = PATHs;
  return request(usersList, methods.PATCH, data).catch(err => {
    throw err;
  });
};

export { updateUser };
