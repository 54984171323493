import { methods, PATHs } from '..';
import { crudRequest } from '../apiClient';

const buildWhere = filter => {
  const { searchText, where = {} } = filter;

  if (searchText) {
    const defaultSearch = [
      { id: { like: `%${searchText}%` } },
      { name: { like: `%${searchText}%` } },
      { uuid: { like: `%${searchText}%` } }
    ];
    if (where.or) {
      where.or = [...where.or, ...defaultSearch];
    } else {
      where.or = defaultSearch;
    }
  }
  return where;
};

const getAudioSpooks = data => {
  const { audioSpooks } = PATHs;
  const { filters, page, size, orderBy, crud } = data;

  const params = {
    filter: {
      fields: ['id', 'name', 'uuid', 'projectId', 'activated'],
      limit: size,
      offset: 0 + size * page,
      include: [
        {
          relation: 'project',
          scope: { fields: ['id', 'name', 'deletedAt'], deleted: true }
        },
        {
          relation: 'devices',
          scope: { fields: ['id', 'unreadNotifications', 'appVersion'], where: { enabled: 1 } }
        }
      ],
      order: 'updatedAt DESC',
      crud
    }
  };

  if (filters) {
    params.filter.where = buildWhere(filters);
  }

  if (orderBy && orderBy.length) {
    const { id, desc } = orderBy[0];
    params.filter.order = `${id} ${desc ? 'DESC' : 'ASC'}`;
  }

  return crudRequest({
    uri: audioSpooks,
    method: methods.GET,
    params
  });
};

const getAudioSpooksCount = data => {
  const { audioSpooksCount } = PATHs;

  const params = {};
  if (data.filters) {
    params.where = buildWhere(data.filters);
  }

  return crudRequest({ uri: audioSpooksCount, method: methods.GET, params });
};

export { getAudioSpooks, getAudioSpooksCount };
