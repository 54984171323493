import { methods, PATHs } from '..';
import { crudRequest } from '../apiClient';

const buildWhere = filter => {
  const { searchText } = filter;
  const where = {};

  if (searchText) {
    const defaultSearch = [
      { name: { like: `%${searchText}%` } },
      { id: { like: `%${searchText}%` } }
    ];
    if (where.or) {
      where.or = [...where.or, ...defaultSearch];
    } else {
      where.or = defaultSearch;
    }
  }
  return where;
};

const getProjects = data => {
  const { projects } = PATHs;
  const { filters, page, size, orderBy, crud, deleted } = data;

  const params = {
    filter: {
      fields: ['id', 'name', 'userCount', 'audioCount'],
      limit: size,
      offset: 0 + size * page,
      order: 'p.updatedAt DESC',
      crud
    }
  };

  if (deleted) {
    params.filter.fields.push('deletedAt');
    params.filter.deleted = true;
  }

  if (filters) {
    params.filter.where = buildWhere(filters);
  }

  if (orderBy && orderBy.length) {
    const { id, desc } = orderBy[0];
    params.filter.order = `${id} ${desc ? 'DESC' : 'ASC'}`;
  }

  return crudRequest({ uri: projects, method: methods.GET, params });
};

const getProjectsCount = data => {
  const { projectsCount } = PATHs;

  const params = {};
  if (data.filters && data.filters.searchText) {
    params.where = buildWhere(data.filters);
  }
  return crudRequest({ uri: projectsCount, method: methods.GET, params });
};

export { getProjects, getProjectsCount };
