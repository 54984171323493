import React from 'react';
import { Bar } from 'react-chartjs-2';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { literal } from '../../../../core/utils';
import { Row, Col } from 'reactstrap';
import styles from './AudiospookGraph.module.css';

const limitDefaults = {
  dBA: { max: 120, high: 100 },
  dBC: { max: 120, high: 100 },
  dBZ: { max: 120, high: 100 },
  dbAMin: { max: 120, high: 100 },
  dbCMin: { max: 120, high: 100 },
  dbZMin: { max: 120, high: 100 },
  dbAMax: { max: 120, high: 100 },
  dbCMax: { max: 120, high: 100 },
  dbZMax: { max: 120, high: 100 },
};

const defaultBackgroundColor = 'rgba(0,91,91,1)';

const colorLimitsDefaults = {
  dBA: {
    default: 'rgba(0,139,150,1)',
    high: 'rgba(255,153,51,1)',
    max: 'rgba(255,51,51,1)',
  },
  dBC: {
    default: 'rgba(100,190,200,1)',
    high: 'rgba(255,153,51,1)',
    max: 'rgba(255,51,51,1)',
  },
  dBZ: {
    default: 'rgba(142,207,215,1)',
    high: 'rgba(255,153,51,1)',
    max: 'rgba(255,51,51,1)',
  },
};

const MIN_VALUE = 10;

const spectralKeys = [
  'dB_20',
  'dB_25',
  'dB_31',
  'dB_40',
  'dB_50',
  'dB_63',
  'dB_80',
  'dB_100',
  'dB_125',
  'dB_160',
  'dB_200',
  'dB_250',
  'dB_315',
  'dB_400',
  'dB_500',
  'dB_630',
  'dB_800',
  'dB_1000',
  'dB_1250',
  'dB_1600',
  'dB_2000',
  'dB_2500',
  'dB_3150',
  'dB_4000',
  'dB_5000',
  'dB_6300',
  'dB_8000',
  'dB_10000',
];
const mainKeys = ['dBA', 'dBC', 'dBZ'];

const spectralLabels = [
  '20Hz',
  '25Hz',
  '31,5Hz',
  '40Hz',
  '50Hz',
  '63Hz',
  '80Hz',
  '100Hz',
  '125Hz',
  '160Hz',
  '200Hz',
  '250Hz',
  '315Hz',
  '400Hz',
  '500Hz',
  '630Hz',
  '800Hz',
  '1000Hz',
  '1250Hz',
  '1600Hz',
  '2000Hz',
  '2500Hz',
  '3150Hz',
  '4000Hz',
  '5000Hz',
  '6300Hz',
  '8000Hz',
  '10000Hz',
];
const mainLabels = ['LA eq', 'LC eq', 'LZ eq'];

export default function AudiospookGraph({ data }) {
  const barOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          barPercentage: 1.2,
          gridLines: {
            offsetGridLines: false,
            lineWidth: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            max: 120.0,
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
      custom: CustomTooltips,
      callbacks: {
        labelColor: tooltipItem => {
          let labelIndex = mainLabels.indexOf(tooltipItem.label);
          let color = defaultBackgroundColor;
          if (labelIndex > -1) {
            color = colorLimitsDefaults[mainKeys[labelIndex]].default;
          }
          return {
            backgroundColor: color,
          };
        },
        label: tooltipItem => {
          let labelIndex = mainLabels.indexOf(tooltipItem.label);
          let text = literal('simso.spectrum');
          if (labelIndex > -1) {
            text = mainKeys[labelIndex];
          }
          return `${text}  <strong>${tooltipItem.value}</strong>`;
        },
      },
    },
    maintainAspectRatio: false,
    legend: { display: false },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  return (
    <>
      <Row>
        <Col>
          <div className={styles.legendContainer}>
            <div className={styles.legendItem}>
              <div
                className={styles.legendColor}
                style={{ backgroundColor: defaultBackgroundColor }}
              ></div>
              <div className={styles.legendText}>{literal('simso.spectrum')}</div>
            </div>
            <div className={styles.legendItem}>
              <div
                className={styles.legendColor}
                style={{ backgroundColor: colorLimitsDefaults.dBA.default }}
              ></div>
              <div className={styles.legendText}>{'dBA'}</div>
            </div>
            <div className={styles.legendItem}>
              <div
                className={styles.legendColor}
                style={{ backgroundColor: colorLimitsDefaults.dBC.default }}
              ></div>
              <div className={styles.legendText}>{'dBC'}</div>
            </div>
            <div className={styles.legendItem}>
              <div
                className={styles.legendColor}
                style={{ backgroundColor: colorLimitsDefaults.dBZ.default }}
              ></div>
              <div className={styles.legendText}>{'dBZ'}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.chartContainer}>
            <Bar data={parseData(data)} options={barOptions} />
          </div>
        </Col>
      </Row>
    </>
  );
}

function parseData(data) {
  const mainData = [];
  const spectralData = [];
  const backgroundColor = Array(29).fill(defaultBackgroundColor);
  Object.keys(data).forEach(key => {
    let value = data[key];
    if (value < MIN_VALUE) value = 0;
    if (spectralKeys.indexOf(key) > -1) {
      spectralData.push(value);
    } else if (mainKeys.indexOf(key) > -1) {
      mainData.push(value);
      const { max, high } = limitDefaults[key];
      if (value >= max) backgroundColor.push(colorLimitsDefaults[key].max);
      else if (value >= high) backgroundColor.push(colorLimitsDefaults[key].high);
      else backgroundColor.push(colorLimitsDefaults[key].default);
    }
  });

  const barData = [...spectralData, 0, ...mainData];

  let labels = spectralLabels;
  const barLabels = [...labels, '', ...mainLabels];

  return {
    labels: barLabels,
    datasets: [
      {
        label: literal('simso.spectrum'),
        backgroundColor,
        borderWidth: 0,
        hoverBackgroundColor: 'rgba(32,168,216,0.3)',
        hoverBorderColor: 'rgba(32,168,216,0.5)',
        data: barData,
      },
    ],
  };
}
