import React, { useState } from 'react';
import ProjectRowActions from './ProjectRowActions';
import CrudList from '../../Common/ListItems/CrudList';
import ColItemId from '../../Common/ListItems/Columns/ColItemId/ColItemId';
import { ProjectDetailUri } from '../../../routes';
import { PATHs, projectsAPI } from '../../../core/requests';
import { storage, literal } from '../../../core/utils';

const ProjectsList = () => {
  const user = storage.getPersistentItem('user') || {};
  const imAdmin = user.role && user.role.name === 'ADMIN';

  const [isOpen, setIsOpen] = useState(false);
  const [instance, setInstance] = useState();
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      Header: '#',
      id: 'id',
      accessor: 'id',
      width: 60,
      Cell: props => <ColItemId {...props} uriGenerator={ProjectDetailUri} />
    },
    {
      Header: literal('general.name'),
      id: 'name',
      accessor: 'name',
      minWidth: 120
    },
    {
      Header: literal('project.users'),
      canSortBy: false,
      accessor: 'userCount.count',
      width: 150
    },
    {
      Header: literal('project.audios'),
      canSortBy: false,
      accessor: 'audioCount.count',
      width: 150,
      align: 'center'
    },
    {
      Header: literal('general.actions'),
      id: 'actions',
      width: 150,
      accessor: 'id',
      canSortBy: false,
      Cell: props =>
        ProjectRowActions(props, value => {
          toggleOpen();
          setInstance(value);
        })
    }
  ];

  return (
    <CrudList
      endpoint={PATHs.projects}
      title={literal('project.title')}
      columns={columns}
      usePagination
      canCreate={imAdmin && ProjectDetailUri}
      deleteModal={{
        isOpen,
        clickDelete: projectsAPI.deleteProject,
        toggle: toggleOpen,
        instance,
        type: 'project'
      }}
    />
  );
};

export default ProjectsList;
