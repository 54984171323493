import { request } from '../apiClient';
import { methods, PATHs } from '..';

function activateAudiospook(id, params) {
  const uri = PATHs.audiospookActivate(id);

  return request(uri, methods.POST, params).catch(err => {
    return Promise.reject(err);
  });
}

export { activateAudiospook };
