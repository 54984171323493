import React from 'react';
import { Col } from 'reactstrap';
import styles from './DBLabel.module.scss';

const DBLabel = props => {
  const { value, label, md = '4' } = props;
  return (
    <Col md={md} sm={12} className="d-flex">
      <div className={props.size && props.size === 'large' ? styles.mainDbaLarge : styles.mainDba}>{value || ''}</div>
      <div>
        <div className={styles.dbaLabel}>{label}</div>
      </div>
    </Col>
  );
};

export default DBLabel;
