import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';

import styles from './User.module.css';
import Avatar from '../../Common/Avatar';
import Detail from '../../Common/Detail';
import { app } from '../../../config';
import Loading from '../../Common/Loading';
import { history, literal, toastUtils } from '../../../core/utils';
import { validEmail } from '../../../core/utils/validation';
import { UserDetailUri } from '../../../routes';
import { usersAPI } from '../../../core/requests';

const UserInfo = props => {
  const { data, onCreate } = props;
  const { ROLES } = app;

  const [image, setImage] = useState();
  const [canSubmit, setCanSubmit] = useState();
  const [user, setUser] = useState();

  if (data) {
    const { id, name, lastName, email, role, projects } = data;

    if (id) {
      if (!image && !!data.image) setImage(data.image);
      if (typeof canSubmit !== 'boolean') {
        setCanSubmit(!!email && !!email.length);
      }
    }

    if (!user) {
      const defaultRole = ROLES[0].name;
      setUser({ name, lastName, email, role: role || defaultRole, projects });
    }

    const setEmail = value => {
      user.email = value;
      const hasEmail =
        !!user.email && !!user.email.length && !!validEmail(user.email);

      const rerender = canSubmit !== hasEmail;
      if (rerender) {
        setUser({ ...user });
        setCanSubmit(hasEmail);
      }
    };

    const setAttribute = (value, attribute) => {
      user[attribute] = value;
    };

    const submitForm = () => {
      const { id } = props.data;
      const data = { ...user, id, image };
      data.type = typeof user.role === 'string' ? user.role : role && role.name;

      const toastId = toastUtils.updating();
      usersAPI
        .updateUser(data)
        .then(userCreated => {
          if (userCreated) {
            toastUtils.toast.update(toastId, {
              type: toastUtils.toast.TYPE.SUCCESS,
              autoClose: 5000,
              render: literal('user.successUpsert')
            });

            if (!id) {
              onCreate && onCreate(userCreated);
              history.push(UserDetailUri(userCreated.id));
            }
          } else defaultError();
        })
        .catch(err => {
          if (err && err.details && err.details.messages) {
            const { email } = err.details.messages;
            if (email[0] === 'Email already exists') {
              defaultError('user.errorMailExists');
            } else defaultError();
          } else defaultError();
        });

      function defaultError(message = 'user.errorUpsert') {
        toastUtils.toast.update(toastId, {
          type: toastUtils.toast.TYPE.ERROR,
          autoClose: 5000,
          render: literal(message)
        });
      }
    };

    function supplantUser() {
      usersAPI.supplantUser(id).then(token => {
        toastUtils.info({ message: literal('user.successSupplant') });
        if (token) window.location.reload();
      });
    }

    return (
      <div className="mt-3">
        <Col className={styles.container}>
          <Row>
            <Col sm={3} md={4} xl={3}>
              <Row>
                <Col md={12}>
                  <Avatar
                    alt="imagen"
                    src={image}
                    onLoaded={setImage}
                    name={'user'}
                  />
                </Col>
                {!!id && id > 0 && (
                  <Col md={12} className="text-center mt-3">
                    <p>
                      <b>{literal('project.title')}: </b>
                      {(projects && projects.length) || 0}
                    </p>
                    {role && role.name !== 'ADMIN' && (
                      <p>
                        <Button color="secondary" onClick={supplantUser}>
                          {literal('user.supplant')}
                        </Button>
                      </p>
                    )}
                  </Col>
                )}
              </Row>
            </Col>
            <Col sm={9} md={8} xl={9}>
              <Row>
                <Detail
                  inputData={{
                    type: 'text',
                    label: literal('user.name'),
                    name: 'name',
                    value: name,
                    placeholder: literal('user.phName')
                  }}
                  onChange={e => setAttribute(e, 'name')}
                />

                <Detail
                  inputData={{
                    type: 'text',
                    label: literal('user.lastName'),
                    name: 'lastname',
                    value: lastName,
                    placeholder: literal('user.phLastName')
                  }}
                  onChange={e => setAttribute(e, 'lastName')}
                />

                <Detail
                  inputData={{
                    type: 'select',
                    label: literal('user.role'),
                    name: 'role',
                    value: role && role.name,
                    options: ROLES.map(role => (
                      <option key={role.name} value={role.name}>
                        {role.label}
                      </option>
                    ))
                  }}
                  onChange={e => setAttribute(e, 'role')}
                />

                <Detail
                  inputData={{
                    type: 'text',
                    label: literal('user.email'),
                    name: 'email',
                    value: email,
                    editable: !id,
                    placeholder: literal('user.phEmail')
                  }}
                  onChange={setEmail}
                />
              </Row>
              <Button
                color="primary"
                disabled={!canSubmit}
                onClick={submitForm}
                className="float-right"
              >
                {literal('project.save')}
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else return <Loading />;
};

export default UserInfo;
