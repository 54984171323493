import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row, Button } from 'reactstrap';
import * as moment from 'moment';

import styles from './Audiospook.module.scss';
import AudiospookGraph from './Graph/AudiospookGraph';
import { audiospooksAPI } from '../../../core/requests';
import Loading from '../../Common/Loading/Loading';
import { literal } from '../../../core/utils';
import checkPermission from '../../../core/permissions/checkPermission';
import { ModalRecord } from './Info/modals';
import { Detail } from '../../Common';

function AudiospookAudio(props) {
  const { id, data } = props;
  const [audioData, setAudioData] = useState();
  const [duration, setDuration] = useState(moment.duration(10, 'minutes').as('hours'));
  let fetching = false;

  useEffect(() => {
    let polling;
    if (id && id > 0 && data) {
      if (data.activated) {
        polling = setInterval(() => {
          if (!fetching) {
            fetching = true;
            audiospooksAPI.getAudioLastData(id).then(data => {
              fetching = false;
              let parsedData = data;
              if (data && data.date) {
                const now = moment();
                if (now.diff(moment(data.date), 'seconds') > 15) {
                  parsedData = null;
                }
              }
              setAudioData(parsedData);
            });
          }
        }, 5000);
      } else {
        setAudioData(null);
      }

      return () => polling && clearInterval(polling);
    }
  }, [id, data, audioData]);

  if (data) {
    const hasDevices = data.devices && data.devices.length > 0;

    return (
      (typeof audioData === 'undefined' ? (
        <Loading />
      ) : audioData === null ? (
        <h3 className="text-center p-3">
          {literal(`simso.${!!data.activated ? 'noData' : 'noDataInactive'}`)}
        </h3>
      ) : (
        <div className="mt-1 w-100">
          <Row>
            <Col xs={12} className="mb-3">
              <Row>
                {checkPermission(`audiospooks.tabs.history`) && (
                  <Fragment>
                    <Detail
                      inputData={{
                        type: 'duration',
                        value: duration,
                        md: 2,
                        colStyle: 'offset-md-8',
                      }}
                      onChange={setDuration}
                    />

                    <Col sm={2} className={styles.historicContainer}>
                      <Button className={'w-100'} color={'primary'} onClick={setForced}>
                        {literal(`simso.seeHistoric`)}
                      </Button>
                    </Col>
                  </Fragment>
                )}
              </Row>
              {checkModal() && hasDevices && (
                <div className={styles.modalRecord}>
                  <ModalRecord id={data.id} disabled={!data.activated} withMargin={false} />
                </div>
              )}
            </Col>
            <Col>
              <Row className="mb-3">
                <Col md={12} className="d-flex justify-content-center">
                  <div className={styles.mainDba}>{(audioData && audioData.dBA) || ''}</div>
                  <div>
                    <div className={styles.dbaLabel}>dBA 5s</div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <div id="LAeq" className={styles.gridValue}>
                    {(audioData && `${audioData.dBA || '-'} LA eq`) || ''}
                  </div>
                  <div id="LApk" className={styles.gridValue}>
                    {(audioData && `${audioData.dBA_peak || '-'} LA peak`) || ''}
                  </div>
                  <div id="LAmx" className={styles.gridValue}>
                    {(audioData && audioData.dBA_max
                      ? `${audioData.dBA_max} LA max`
                      : `- LA mix`) || ''}
                  </div>
                  <div id="LAmn" className={styles.gridValue}>
                    {(audioData && audioData.dBA_min
                      ? `${audioData.dBA_min || '-'} LA min`
                      : `- LA min`) || ''}
                  </div>
                </Col>
                <Col md={4}>
                  <div id="LCeq" className={styles.gridValue}>
                    {(audioData && `${audioData.dBC || '-'} LC eq`) || ''}
                  </div>
                  <div id="LCpk" className={styles.gridValue}>
                    {(audioData && `${audioData.dBC_peak || '-'} LC peak`) || ''}
                  </div>
                  <div id="LCmx" className={styles.gridValue}>
                    {(audioData && audioData.dBC_max
                      ? `${audioData.dBC_max || '-'} LC max`
                      : `- LC mix`) || ''}
                  </div>
                  <div id="LCmn" className={styles.gridValue}>
                    {(audioData && audioData.dBC_min
                      ? `${audioData.dBC_min} LC min`
                      : `- LC min`) || ''}
                  </div>
                </Col>
                <Col md={4}>
                  <div id="LZeq" className={styles.gridValue}>
                    {(audioData && `${audioData.dBZ || '-'} LZ eq`) || ''}
                  </div>
                  <div id="LZpk" className={styles.gridValue}>
                    {(audioData && `${audioData.dBZ_peak} LZ peak`) || ''}
                  </div>
                  <div id="LZmx" className={styles.gridValue}>
                    {(audioData && audioData.dBZ_max
                      ? `${audioData.dBZ_max || '-'} LZ max`
                      : `- LZ max`) || ''}
                  </div>
                  <div id="LZmn" className={styles.gridValue}>
                    {(audioData && audioData.dBZ_min
                      ? `${audioData.dBZ_min || '-'} LZ min`
                      : `- LZ min`) || ''}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="d-flex justify-content-center">
                  {audioData && (
                    <div className={styles.chartContainer}>
                      <div className={styles.scrollableChart}>
                        <AudiospookGraph data={audioData} />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )) || <Loading />
    );
  } else {
    return <Loading />;
  }

  function checkModal() {
    return data.id > 0 && checkPermission(`audiospooks.sendAudio`);
  }

  function setForced() {
    const forced = { duration };
    return props.setForced(forced);
  }
}

export default AudiospookAudio;
