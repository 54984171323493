import { request } from '../apiClient';
import { methods, PATHs } from '..';

function recordSimsos(id) {
  const uri = PATHs.recordSimsos(id);

  return request(uri, methods.GET).catch(err => {
    return Promise.reject(err);
  });
}

export { recordSimsos };
