import React, { useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardFooter
} from 'reactstrap';
import * as queryString from 'query-string';

import { usersAPI } from '../../../core/requests';
import {
  history,
  literal,
  toastUtils,
  saveUserToken
} from '../../../core/utils';
import Detail from '../../Common/Detail';

const Forgot = props => {
  const { search } = props.location;
  const { email, token, userId } = queryString.parse(search);

  const [firstPwd, setFirstPwd] = useState('');
  const [secondPwd, setSecondPwd] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    setCanSubmit(firstPwd.length && firstPwd === secondPwd);
  }, [firstPwd, secondPwd]);

  const sendMail = () => {
    usersAPI.setPassword({ userId, token, firstPwd }).then(() => {
      usersAPI
        .login({ email, password: firstPwd })
        .then(res => {
          if (res) history.push('/projects');
        })
        .catch(err => {
          toastUtils.notify(err.message);
          return Promise.reject(err);
        });
    });
  };

  const backLogin = () => {
    history.push('/login');
  };

  function checkCanSubmit(value, which) {
    if (which === 'first') setFirstPwd(value);
    else setSecondPwd(value);
  }

  return (
    <div className="app d-flex h-100 v-100 justify-content-center align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col sm={8}>
            <Card className="p-4">
              <CardBody>
                <h2 className="pb-3">{literal('session.identity.title')}</h2>
                <h5 className="pb-2">{literal('session.identity.subtitle')}</h5>

                <Row className="mt-4">
                  <Detail
                    inputData={{
                      label: literal('user.email'),
                      type: 'text',
                      name: 'email',
                      value: email,
                      editable: false
                    }}
                  />

                  <Detail
                    inputData={{
                      type: 'text',
                      label: literal('session.identity.firstPwd'),
                      name: 'first',
                      value: firstPwd,
                      placeholder: literal('user.password')
                    }}
                    onChange={e => checkCanSubmit(e, 'first')}
                  />

                  <Detail
                    inputData={{
                      type: 'text',
                      label: literal('session.identity.secondPwd'),
                      name: 'second',
                      value: secondPwd,
                      placeholder: literal('user.password')
                    }}
                    onChange={e => checkCanSubmit(e, 'second')}
                  />
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  color={'primary'}
                  disabled={!canSubmit}
                  className="float-right"
                  onClick={sendMail}
                >
                  {literal('session.identity.button')}
                </Button>
                <Button
                  color={'default'}
                  className="float-right"
                  onClick={backLogin}
                >
                  {literal('session.identity.backLogin')}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Forgot;
