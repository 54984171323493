import { request } from '../apiClient';
import { methods, PATHs } from '..';

const patchAttributes = (id, data) => {
  const { patchAttributes } = PATHs;
  return request(patchAttributes(id), methods.PATCH, data).catch(err => {
    throw err;
  });
};

export { patchAttributes };
