import React, { useState } from 'react';
import ControlCenterList from './List/ControlCenterList';

const ControlCenter = () => {
  const [devices] = useState([]);

  return <ControlCenterList devices={devices} />;
};

export default ControlCenter;
