import { PATHs } from '../../core/requests';
import * as get from 'lodash.get';

const imgSource = (src, srcGet, otherwise = null) => {
  const objFrom = srcGet ? get(src, srcGet) : src;
  const base = `${process.env.REACT_APP_API_URL}/api/`;

  return objFrom && objFrom.indexOf('http') === -1
    ? `${base}${PATHs.containerDownload(objFrom)}`
    : otherwise;
};

export { imgSource };
