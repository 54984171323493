import React from 'react';
import { Col } from 'reactstrap';
import common from './common.module.scss';
import { history, imgSource } from '../../../../core/utils';
import { UserDetailUri } from '../../../../routes';
import placeholder from '../../../../assets/img/placeholder.jpg';
import { app } from '../../../../config';

const UserRow = props => {
  const { id, name, lastName, role, image, email } = props.data;
  const url = imgSource(image, null, image || placeholder);

  return (
    <Col
      className={common.colContainer}
      md={12}
      onClick={() => {
        history.push(UserDetailUri(id));
      }}
    >
      <div className="d-flex align-items-center flex-start">
        <div className={`${common.avatarContainer} `}>
          <img src={url} className={common.avatar} alt={'avatar'} />
        </div>

        <div className={`${common.userData} ml-3`}>
          <p>
            <b>
              {name} {lastName}
            </b>
          </p>
          <p>{email || '-'}</p>
          <p>{getRole()}</p>
        </div>
      </div>
    </Col>
  );

  function getRole() {
    const { ROLES } = app;

    let roleValue = '-';
    const { name: roleName } = role;
    if (roleName) {
      let value = ROLES.find(role => role.name === roleName);
      roleValue = value ? value.label : name;
    }
    return roleValue;
  }
};

export default UserRow;
