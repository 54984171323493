import { METHODS } from 'http';
import { logoutUser, userToken, parseError } from '../utils';

const crudRequest = data => {
  const { method, params } = data;
  let { uri } = data;

  if (params) {
    let query = '?';
    query += Object.keys(params).map(key => {
      const value = JSON.stringify(params[key]);
      return `${key}=${encodeURIComponent(value)}&`;
    });
    uri += query;
  }

  return request(uri, method);
};

const request = (uri, method = METHODS.GET, params = null, token) => {
  const host = `${process.env.REACT_APP_API_URL}/api/`;

  const url = `${host}${uri}`;
  const authToken = token || userToken();
  const requestData = {
    method: method,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken
    }
  };
  if (authToken) requestData.headers.Authorization = authToken;
  if (params) requestData.body = JSON.stringify(params);

  return fetch(url, requestData)
    .then(data => {
      let res = {};
      const { status } = data;
      if (status !== 204) res = data && data.json();
      return res;
    })
    .then((res = {}) => {
      let final = Promise.resolve(res);
      if (res && res.error) {
        const { error } = res;
        if (error.statusCode === 401) {
          logoutUser();
        }
        final = Promise.reject(error);
      }

      return final;
    })
    .catch(err => {
      if (!parseError({ err })) throw err;
    });
};

const requestFile = (uri, method = METHODS.POST, { file, fileName }) => {
  if (file) {
    const host = `${process.env.REACT_APP_API_URL}/api/`;

    const formData = new FormData();
    formData.append('file', file, fileName);

    const url = `${host}${uri}`;
    const authToken = userToken();
    const options = {
      method: method,
      headers: { Authorization: authToken },
      body: formData
    };

    return fetch(url, options)
      .then(data => {
        return data.json();
      })
      .then((res = {}) => {
        let final = Promise.resolve(res);
        if (res && res.error) {
          const { error } = res;
          if (error.statusCode === 401) {
            logoutUser();
          }
          final = Promise.reject(error);
        }

        return final;
      });
  }
};

const autenticatedURI = (uri, token = null) => {
  const host = `${process.env.REACT_APP_API_URL}/api/`;
  const authToken = token || userToken();
  const url = `${host}${uri}?access_token=${authToken}`;
  return url;
};

export { request, crudRequest, requestFile, autenticatedURI };
