import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import './App.scss';
import Loading from './components/Common/Loading/Loading';
import Login from './components/Login/Login';
import Forgot from './components/Session/Forgot';
import Identity from './components/Session/Identity';

const loading = () => <Loading />;
toast.configure();

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

function App() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={props => <Login {...props} />}
            default={true}
          />
          <Route
            path="/forgot"
            name="Forgot"
            render={props => <Forgot {...props} />}
          />
          <Route
            path="/identity"
            name="Identity"
            render={props => <Identity {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={props => <DefaultLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
