import { request } from '../apiClient';
import { methods, PATHs } from '..';

const getLatestVersion = () => {
  const { getLatestVerion } = PATHs;

  return request(getLatestVerion, methods.GET)
    .catch(err => {
      return Promise.reject(err);
    });
};

export { getLatestVersion };
