import React, { useState, useEffect } from 'react';
import { history, literal } from '../../../core/utils';
import style from './Table.module.css';
import { Button as ButtonStrap } from 'reactstrap';
import {
  useTable,
  useColumns,
  useRows,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
  useFlexLayout,
} from 'react-table';

import { Table, Row, HeaderRow, Header, Cell, Select, Input } from './Styles';

export default function MyTable({
  loading,
  state,
  endpoint,
  canCreate,
  count,
  title,
  tableActions,
  ...props
}) {
  const instance = useTable(
    {
      state,
      ...props,
    },
    useColumns,
    useRows,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout
  );

  const {
    getTableProps,
    headerGroups,
    pageOptions,
    page,
    state: [{ pageIndex, pageSize }],
    gotoPage,
    prepareRow,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = instance;

  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(10);

  useEffect(() => {
    instance.setAllFilters(filter);
  }, [filter]);

  const updateSearchValue = newValue => {
    setFilter({ ...filter, searchText: newValue });
  };

  const updateSortValue = column => {
    if (!column.sorted || (column.sorted && column.sortedDesc))
      column.toggleSortBy();
    else column.toggleSortBy(false);
  };

  const renderRow = (row, index) => {
    if (!row) {
      return (
        <Row key={`row_${index}`} even={index % 2}>
          <Cell>Loading more...</Cell>
        </Row>
      );
    }
    prepareRow(row);
    return (
      <Row key={`row_${index}`} {...row.getRowProps({ even: index % 2 })}>
        {row.cells.map((cell, cellIndex) => {
          return (
            <Cell
              style={'width:100px'}
              {...cell.getCellProps()}
              key={`row_${index}_cell_${cellIndex}`}
            >
              <span>{cell.render('Cell')}</span>
            </Cell>
          );
        })}
      </Row>
    );
  };

  const tableBody = page && page.map(renderRow);

  const pagination = pageOptions.length ? (
    <div className={style.paginationContainer}>
      <div
        className={style.paginationButton}
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        {literal('general.previous')}
      </div>
      {pageOptions.map(index => {
        return (
          <div
            key={`pagination${index}`}
            className={
              pageIndex != index
                ? style.paginationInnerButton
                : style.paginationInnerButtonActive
            }
            onClick={() => gotoPage(index)}
          >
            {index + 1}
          </div>
        );
      })}
      <div
        className={style.paginationButton}
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        {literal('general.next')}
      </div>
    </div>
  ) : null;

  return (
    <div className={style.container}>
      <div className={style.PageTitle}>
        {title || endpoint.toUpperCase()}
        <div>
          {!!canCreate && (
            <ButtonStrap
              onClick={() => history.push(canCreate('create'))}
              color={'primary'}
              size="sm"
            >
              {literal('general.create')}
            </ButtonStrap>
          )}
          {!!tableActions && tableActions.length && tableActions.map((ta, index) => (
            <ButtonStrap
              key={`tableAction_${index}`}
              className={'ml-1'}
              onClick={() => ta.onClick(instance)}
              color={'primary'}
              size="sm"
            >
              {ta.text}
            </ButtonStrap>
          ))}
        </div>
      </div>

      <div className={style.tableBody}>
        <HeaderTableActions>
          <div className="d-flex justify-content-end align-items-end w-100">
            <Input
              placeholder={literal('general.search')}
              value={(filter && filter.searchText) || ''}
              style={{ height: '32px' }}
              onChange={e => updateSearchValue(e.target.value)}
            />
          </div>
        </HeaderTableActions>
        <Table {...getTableProps()}>
          {headerGroups.map((headerGroup, index) => (
            <HeaderRow
              {...headerGroup.getRowProps()}
              key={`headerRow_${index}`}
            >
              {headerGroup.headers.map((column, index) => (
                <Header
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={`header_${index}`}
                  sorted={column.sorted}
                  sortedDesc={column.sortedDesc}
                  sortedIndex={column.sortedIndex}
                  onClick={e => {
                    if (column.canSortBy) updateSortValue(column);
                  }}
                >
                  {column.render('Header')}
                  <i
                    className={`ml-2
                    ${column.sorted && column.canSortBy
                        ? column.sortedDesc
                          ? 'fa fa-sort-down'
                          : 'fa fa-sort-up'
                        : column.canSortBy
                          ? 'fa fa-sort'
                          : ''
                      }`}
                  />
                </Header>
              ))}
            </HeaderRow>
          ))}
          <div className={style.tableContent}>{tableBody} </div>
        </Table>
        <div
          className="d-flex justify-content-between  align-items-center"
          style={{
            height: '32px',
            borderRadius: '3px',
            marginBottom: '6px',
            marginTop: '12px',
          }}
        >
          <div>
            {literal('general.show')}
            <Select
              value={pageSize}
              style={{
                border: '1px solid #C8CED3',
                borderRadius: '3px',
                height: '32px',
                marginLeft: '8px',
                lineHeight: '14px',
              }}
              onChange={e => {
                setPageCount(Number(e.target.value));
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Select>
            {' de ' + count}
          </div>
          {pagination}
        </div>

        <br />
        <br />
      </div>
    </div>
  );
}

const HeaderTableActions = props => {
  return (
    <div
      style={{
        marginTop: '16px',
        marginBottom: '16px',
      }}
      className="d-flex justify-content-between"
    >
      {props.children}
    </div>
  );
};
