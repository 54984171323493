import React, { useState } from 'react';
import styles from './Thumbnail.module.scss';
import placeholder from '../../../assets/img/placeholder.jpg';
import { imgSource } from '../../../core/utils';
import { Row, Col } from 'reactstrap';
import { containerAPI } from '../../../core/requests';
import * as get from 'lodash.get';

const Thumbnail = props => {
  let { src } = props;
  const noPlaceholder = !src;
  if (!src) src = placeholder;
  const {
    alt = 'thumbnail',
    editable = true,
    name = 'default',
    onLoaded,
    md = 12
  } = props;

  if (typeof src === 'object' && !!src.result) {
    src = get(src, 'result.files.file[0].name');
  }

  let url = imgSource(src, null, src);
  if (src === placeholder) url = src;

  const [thumbnail, setThumbnail] = useState(url);

  const handleChange = event => {
    const { target } = event;
    if (target && target.files) {
      const [file] = target.files;

      const reader = new FileReader();
      reader.onload = evt => {
        setThumbnail(evt.target.result);
        const ext = file.name.split('.').pop();
        const fileName = `${name}-thumbnail-${new Date().getTime()}.${ext}`;
        containerAPI.containerUpload(file, fileName).then(thumbnailName => {
          onLoaded && onLoaded(thumbnailName);
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Col md={md}>
      <Row>
        <div
          className={`${styles.thumbnailContainer} ${editable &&
            styles.editable}`}
        >
          <img className={styles.thumbnailImg} src={thumbnail} alt={alt} />
          {editable && (
            <div className={styles.thumbnailBottom}>
              <div className={styles.thumbnailText}>
                <i className="icon-cloud-upload"></i>
                <input
                  type="file"
                  accept="image/*"
                  className={styles.inputFile}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.thumbnailText}>
                <a
                  disabled={!noPlaceholder}
                  href={url}
                  target="_blank"
                  className={`${styles.thumbnailA} btn btn-primary`}
                >
                  <i className="icon-share-alt"></i>
                </a>

                <input
                  type="file"
                  accept="image/*"
                  className={styles.inputFile}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
        </div>
      </Row>
    </Col>
  );
};

export default Thumbnail;
