import { crudRequest } from '../apiClient';
import { methods, PATHs } from '..';

const deleteProject = id => {
  const { projectDelete } = PATHs;
  const uri = projectDelete(id);

  return crudRequest({ uri, method: methods.DELETE }).catch(err => {
    return Promise.reject(err);
  });
};

export { deleteProject };
