import { request } from '../apiClient';
import { methods, PATHs } from '..';
import {
  saveAdminToken,
  setAdminToken,
  saveUserToken,
  storage
} from '../../utils';

const supplantUser = id => {
  const { supplantUser } = PATHs;
  saveAdminToken();
  return request(supplantUser(id), methods.POST)
    .then(token => {
      if (token && token.id) {
        saveUserToken(token.id, true);
        if (token.user) storage.setPersistentItem('user', token.user);
        return Promise.resolve(token);
      }
    })
    .catch(err => {
      setAdminToken();
      storage.deleteItem('adminToast');
      throw err;
    });
};

export { supplantUser };
