import { requestFile } from '../apiClient';
import { methods, PATHs } from '..';
import * as get from 'lodash.get';

const containerUpload = (file, fileName) => {
  const { containerUpload } = PATHs;

  return requestFile(containerUpload, methods.POST, { file, fileName })
    .then(res => {
      let toReturn = get(res, 'result.files.file[0].name');
      return toReturn || res;
    })
    .catch(Promise.reject);
};

export { containerUpload };
