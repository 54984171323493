import { request } from '../apiClient';
import { methods, PATHs } from '..';

function getAudioHistoryData(id, params) {
  const uri = PATHs.audiospookHistory(id);

  return request( uri, methods.POST, params ).catch(err => {
    return Promise.reject(err);
  });
}

export { getAudioHistoryData };
