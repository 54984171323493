import { request } from '../apiClient';
import { methods, PATHs } from '..';

function deleteAudiospook(id) {
  const uri = PATHs.deleteAudiospook(id);

  return request(uri, methods.DELETE).catch(err => {
    return Promise.reject(err);
  });
}

export { deleteAudiospook };
