import React, { useState, Fragment } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { audiospooksAPI } from '../../../../../core/requests';
import { literal, toastUtils } from '../../../../../core/utils';

const ModalRemoveDevice = props => {
  const { id, disabled = true, onRemove } = props;
  const [isOpen, setIsOpen] = useState(false);

  const clickRemove = () => {
    audiospooksAPI
      .removeDevices(id)
      .then(res => {
        toastUtils.success(literal('simso.removeDevicesSuccess'));
        onRemove && onRemove();
        setIsOpen(false);
        window.location.reload();
      })
      .catch(err => {
        toastUtils.notify(err.message);
      });
  };

  return (
    <Fragment>
      <div>
        <p>
          <Button disabled={disabled} onClick={() => setIsOpen(true)}>
            {literal(`simso.removeDevice`)}
          </Button>
        </p>
      </div>

      <Modal isOpen={isOpen}>
        <ModalHeader>{literal('simso.removeDevice')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="p-3" md={12}>
              {literal('simso.removeDeviceBody')}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => setIsOpen(false)}>
            {literal('general.close')}
          </Button>
          <Button color="primary" onClick={clickRemove}>
            {literal('general.delete')}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ModalRemoveDevice;
