import { request } from '../apiClient';
import { methods, PATHs } from '..';

function updateAudiospookVersionAll() {
  const uri = PATHs.updateAudiospookVersionAll;

  return request(uri, methods.POST)
    .catch(err => {
      return Promise.reject(err);
    });
}

export { updateAudiospookVersionAll };
