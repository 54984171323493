import React from 'react';
import { Line } from 'react-chartjs-2';
import * as moment from 'moment';

const colorDefaults = {
  dBA: 'rgba(23, 110, 117)',
  dBC: 'rgba(53, 71, 29,1)',
  dBZ: 'rgba(149, 120, 159,1)',
};

function AudiospookHistoryGraph(props) {
  let { data } = props;

  const options = {
    responsive: true,
    scales: {
      yAxes: [
        {
          stacked: false,
        },
      ],
    },
  };

  return <Line data={parseData(data, options)} options={options} />;
}

function parseData(data, options) {
  const dbAData = [];
  const dbCData = [];
  const dbZData = [];
  const labels = [];

  data.forEach(({ date, dBA, dBC, dBZ }, idx) => {
    labels.push(idx % 6 === 0 ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '');
    dbAData.push(dBA);
    dbCData.push(dBC);
    dbZData.push(dBZ);
  });

  return {
    labels,
    datasets: [
      {
        label: 'dBA',
        fill: false,
        backgroundColor: colorDefaults.dBA,
        pointBackgroundColor: colorDefaults.dBA,
        borderColor: colorDefaults.dBA,
        borderWidth: 0.7,
        pointHighlightStroke: colorDefaults.dBA,
        data: dbAData,
        radius: 1,
      },
      {
        label: 'dBC',
        fill: false,
        backgroundColor: colorDefaults.dBC,
        pointBackgroundColor: colorDefaults.dBC,
        borderColor: colorDefaults.dBC,
        borderWidth: 0.7,
        pointHighlightStroke: colorDefaults.dBC,
        data: dbCData,
        radius: 1,
      },
      {
        label: 'dBZ',
        fill: false,
        backgroundColor: colorDefaults.dBZ,
        pointBackgroundColor: colorDefaults.dBZ,
        borderColor: colorDefaults.dBZ,
        borderWidth: 0.7,
        pointHighlightStroke: colorDefaults.dBZ,
        data: dbZData,
        radius: 1,
      },
    ],
  };
}

export default AudiospookHistoryGraph;
