import React from 'react';
import { Button } from 'reactstrap';

const ControlCenterActions = ({ value: simsoId }, onClickUpdate) => (
  <div className="d-flex align-items-center">
    <Button
      onClick={() => onClickUpdate(simsoId)}
      className={'icon-refresh bg-transparent text-primary rounded-circle btn-sm ml-1'}
      color={'primary'}
    />
  </div>
);

export default ControlCenterActions;
