import { audiospooksAPI } from '../../../../core/requests';
import { toastUtils, literal } from '../../../../core/utils';

const updateSimso = simso => {
  const { id, name } = simso;

  if (id || name) {
    return audiospooksAPI
      .updateAudiospook(simso)
      .then(({ id }) => {
        toastUtils.success(literal(`simso.${id ? 'save' : 'create'}Success`));
        return audiospooksAPI.getAudiospook(id);
      })
      .catch(e => {
        if (e.name === 'ALREADY_EXISTS') {
          toastUtils.notify(literal('simso.errors.alreadyExists'));
        } else toastUtils.notify(literal('simso.errors.generalSaving'));
      });
  } else {
    toastUtils.notify(literal('simso.errors.missingFields'));
    return Promise.resolve();
  }
};

export { updateSimso };
