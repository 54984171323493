import React, { useState } from 'react';
import { Row, Button } from 'reactstrap';
import styles from './ElementsList.module.scss';
import {
  AudiospookRow,
  ProjectRow,
  UserRow,
  MediaRow,
  CalibrationRow
} from './ElementRow';
import { literal } from '../../../core/utils';
import { Loading, ModalRelation } from '../../Common';

const ElementsList = props => {
  const {
    elements,
    type = 'audiospooks',
    header,
    modalRelation,
    onSave,
    historySearch
  } = props;

  const toRender = {
    audiospooks: AudiospookRow,
    projects: ProjectRow,
    users: UserRow,
    medias: MediaRow,
    calibrations: CalibrationRow
  };
  const translate = {
    users: literal('user.title'),
    projects: literal('project.title'),
    audiospooks: literal('simso.title'),
    medias: literal('simso.media.title'),
    calibrations: literal('general.changes')
  };

  const [showModal, setShowModal] = useState(false);

  const Component = toRender[type];
  if (elements) {
    const toggle = () => {
      setShowModal(!showModal);
    };

    return (
      <div className={styles.rowContainer}>
        <Row className={styles.elementsContainer}>
          {header && <div className={styles.listHeader}>{header}</div>}
          {elements[type] &&
            elements[type].map(element => {
              return (
                <Component
                  data={element}
                  key={`${type}-${element.id}`}
                  historySearch={historySearch}
                />
              );
            })}
          {elements[type] && !elements[type].length && (
            <div className={styles.noRelated}>
              <h3>
                {literal('general.noRelated').replace(
                  '{items}',
                  translate[type]
                )}
              </h3>
            </div>
          )}
        </Row>
        {modalRelation && (
          <div className={`d-flex justify-content-center align-items-end`}>
            <Button color="primary" onClick={toggle}>
              {`${literal('general.manage')} ${literal('project.title')}`}
            </Button>
          </div>
        )}
        {modalRelation && showModal && (
          <ModalRelation
            data={{ ...modalRelation, type }}
            toggle={toggle}
            onSave={onSave}
          />
        )}
      </div>
    );
  } else return <Loading />;
};

export default ElementsList;
