import storage from './storage';
import { LoginPath } from '../../routes';
import history from './history';

const checkAuth = () => {
  return (
    !!localStorage.getItem('Authorization') ||
    !!sessionStorage.getItem('Authorization')
  );
};

const checkRoleAdmin = () => {
  let imAdmin = false;
  if (checkAuth()) {
    const user = storage.getPersistentItem('user');
    imAdmin = user && user.role && user.role.name === 'ADMIN';
  }
  return imAdmin;
};

const saveUserToken = (token, rememberUser) => {
  const storageSystem = rememberUser ? localStorage : sessionStorage;
  storage.setPersistentStorageSystem(storageSystem);
  storage.setPersistentItem('Authorization', token);
};

const saveAdminToken = () => {
  const token = storage.getPersistentItem('Authorization');
  const user = storage.getPersistentItem('user');
  if (token) {
    storage.setPersistentItem('AdminToken', token);
    storage.setPersistentItem('UserAdmin', user);
  }
};

const setAdminToken = () => {
  const token = storage.getPersistentItem('AdminToken');
  const user = storage.getPersistentItem('UserAdmin');
  if (token) {
    storage.setPersistentItem('Authorization', token);
    storage.setPersistentItem('user', user);
    storage.deletePersistentItem('AdminToken');
    storage.deletePersistentItem('UserAdmin');
  }
};

const checkAdminToken = () => {
  return !!storage.getPersistentItem('AdminToken');
};

const userToken = () => {
  return storage.getPersistentItem('Authorization');
};

const logoutUser = () => {
  storage.clearAllData();
  history.push(LoginPath);
};

export {
  checkAuth,
  checkRoleAdmin,
  userToken,
  saveUserToken,
  logoutUser,
  saveAdminToken,
  setAdminToken,
  checkAdminToken
};
