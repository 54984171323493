import { login } from './auth/login';

import {
  getUser,
  getUsers,
  getUsersCount,
  userProjects,
  updateUser,
  supplantUser,
  forgotPassword,
  setPassword,
  deleteUser,
  patchAttributes,
} from './users';
import {
  getProject,
  getProjects,
  getProjectsCount,
  deleteProject,
  updateProject,
  recordSimsos,
} from './projects';
import {
  getAudiospook,
  getAudioLastData,
  getAudioSpooks,
  getAudioSpooksCount,
  updateAudiospook,
  getAudioHistoryData,
  audioExportData,
  activateAudiospook,
  configureAudiospook,
  recordAudiospook,
  deleteAudiospook,
  removeDevices,
  updateAudiospookVersion,
  updateAudiospookVersionAll
} from './audiospooks';
import { containerUpload } from './container';
import { getLatestVersion } from './devices';

const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

const containerBase = `Containers/${process.env.REACT_APP_BUCKET}/`;
const PATHs = {
  usersList: 'Users',
  userDetail: id => `Users/${id}`,
  userProjects: id => `Users/${id}/projects`,
  supplantUser: id => `Users/${id}/supplant`,
  userDelete: id => `Users/${id}`,
  usersCount: 'Users/count',
  forgotPassword: 'Users/forgotPassword',
  setPassword: 'Users/reset-password',
  login: 'Users/login?include=user',
  patchAttributes: id => `Users/${id}`,
  audioSpooks: 'Audiospooks',
  audiospook: id => `Audiospooks/${id}`,
  audioSpooksCount: 'Audiospooks/count',
  audiospookLastData: id => `Audiospooks/${id}/lastData`,
  audiospookHistory: id => `Audiospooks/${id}/data`,
  audiospookExport: id => `Audiospooks/${id}/dataExport`,
  audiospookActivate: id => `Audiospooks/${id}/activate`,
  removeDevices: id => `Audiospooks/${id}/remove-devices`,
  configureAudiospook: id => `Audiospooks/${id}/push-configuration`,
  recordAudiospook: id => `Audiospooks/${id}/record`,
  deleteAudiospook: id => `Audiospooks/${id}`,
  audiospookDownloadExport: `Audiospooks/download`,
  updateAudiospookVersion: id => `Audiospooks/${id}/update-version`,
  updateAudiospookVersionAll: `Audiospooks/update-version`,
  projects: 'Projects',
  projectDetail: id => `Projects/${id}`,
  projectDelete: id => `Projects/${id}`,
  projectsCount: 'Projects/count',
  recordSimsos: id => `Projects/${id}/record`,
  containerUpload: `${containerBase}upload`,
  containerDownload: key => `${containerBase}download/${key}`,
  containerDownloadOctet: key => `${containerBase}download-octet/${key}`,
  getLatestVerion: 'Devices/latest-version'
};

const usersAPI = {
  getUser,
  getUsers,
  getUsersCount,
  login,
  userProjects,
  forgotPassword,
  setPassword,
  updateUser,
  supplantUser,
  deleteUser,
  patchAttributes,
};

const projectsAPI = {
  getProject,
  getProjects,
  updateProject,
  getProjectsCount,
  deleteProject,
  recordSimsos,
};

const audiospooksAPI = {
  getAudioSpooks,
  getAudioSpooksCount,
  getAudioHistoryData,
  audioExportData,
  getAudioLastData,
  getAudiospook,
  updateAudiospook,
  activateAudiospook,
  configureAudiospook,
  recordAudiospook,
  deleteAudiospook,
  removeDevices,
  updateAudiospookVersion,
  updateAudiospookVersionAll
};

const containerAPI = {
  containerUpload,
};

const deviceApi = {
  getLatestVersion
};

export { methods, PATHs, usersAPI, projectsAPI, audiospooksAPI, containerAPI, deviceApi };
