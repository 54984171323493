import React, { Fragment } from 'react';

import Avatar from '../../../Common/Avatar';
import { ModalRecord, ModalActivate, ModalRemoveDevice } from './modals';
import checkPermission from '../../../../core/permissions/checkPermission';
import { storage } from '../../../../core/utils';

const AvatarButtons = props => {
  const { simso = {}, setSimso, editable, onSave } = props;

  const user = storage.getPersistentItem('user') || {};
  const imAdmin = user.role && user.role.name === 'ADMIN';

  const { uuid, plainPass, activated, devices } = simso;
  const hasDevices = devices && devices.length > 0;
  const adminCredentials = imAdmin ? { uuid, plainPass } : {};

  return (
    <Fragment>
      <Avatar
        alt="imagen"
        src={simso.image}
        onLoaded={image => setSimso({ ...simso, image })}
        editable={editable}
        xl={true}
        name={'audiospook'}
      />

      <div className="buttons mt-3 text-center">
        {checkModal('activate') && (
          <ModalActivate
            id={simso.id}
            onSave={onSaveActivate}
            activated={activated}
            disabled={!activated}
            hasDevices={hasDevices}
            imAdmin={imAdmin}
            credentials={adminCredentials}
          />
        )}

        {checkModal('sendAudio') && hasDevices && (
          <ModalRecord id={simso.id} disabled={!activated} />
        )}

        {checkModal('removeDevice') && hasDevices && (
          <ModalRemoveDevice
            id={simso.id}
            disabled={!hasDevices}
            onRemove={() =>
              setSimso({ ...simso, activated: false, devices: [] })
            }
          />
        )}
      </div>
    </Fragment>
  );

  function checkModal(action) {
    return simso.id > 0 && checkPermission(`audiospooks.${action}`);
  }

  function onSaveActivate(value) {
    const data = { ...simso, activated: value };
    onSave(data);
    setSimso(data);
    window.location.reload();
  }
};

export default AvatarButtons;
