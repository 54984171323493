import React, { useState, useEffect } from 'react';
import AudiospookAudio from './AudiospookAudio';
import AudiospookInfo from './AudiospookInfo';
import Tabs from '../../Common/tabs/Tabs';
import { literal } from '../../../core/utils';
import AudiospookHistoryData from './AudiospookHistoryData';
import AudiospookDownloadData from './AudiospookDownloadData';
import { audiospooksAPI } from '../../../core/requests';
import ElementsList from '../../Common/ElementsList';
import checkPermission from '../../../core/permissions/checkPermission';

function Audiospook(props) {
  const { id } = props.match.params;
  const [data, setData] = useState();
  const [forcedIndex, setForcedIndex] = useState(null);
  const [forcedHistoryData, setForcedHistoryData] = useState(null);

  function getAudio(id) {
    audiospooksAPI
      .getAudiospook(id)
      .then(simso => {
        simso.calibrations.forEach(calibration => {
          if (!calibration.range) calibration.range = simso.range;
        });
        setData(simso);
      })
      .catch(() => setData({ error: true }));
  }

  useEffect(() => {
    if (id && id > 0) getAudio(id);
    else setData({});
  }, []);

  const tabs = [];

  if (checkPermission('audiospooks.tabs.info')) {
    tabs.push({
      name: literal('simso.info'),
      component: <AudiospookInfo data={data} onSave={setData} />,
    });
  }
  if (checkPermission('audiospooks.tabs.calibration')) {
    tabs.push({
      name: literal('simso.calibrationTab'),
      component: <ElementsList elements={data} type={'calibrations'} />,
      disabled: !data || !data.id,
    });
  }
  if (checkPermission('audiospooks.tabs.data')) {
    tabs.push({
      name: literal('simso.data'),
      component: (
        <AudiospookAudio
          id={id}
          data={data}
          setForced={data => {
            setForcedIndex('4');
            setForcedHistoryData(data);
          }}
        />
      ),
      disabled: !data || !data.id,
    });
  }
  if (checkPermission('audiospooks.tabs.history')) {
    tabs.push({
      name: literal('simso.history'),
      component: (
        <AudiospookHistoryData
          id={id}
          forced={forcedHistoryData}
          setForced={setForcedHistoryData}
        />
      ),
      disabled: !data || !data.id,
    });
  }
  if (checkPermission('audiospooks.tabs.download')) {
    tabs.push({
      name: literal('simso.download'),
      component: <AudiospookDownloadData id={id} />,
      disabled: !data || !data.id,
    });
  }
  if (checkPermission('audiospooks.tabs.audios')) {
    tabs.push({
      name: literal('simso.media.title'),
      component: <ElementsList elements={data} type={'medias'} />,
      disabled: !data || !data.id,
    });
  }

  return (
    <Tabs
      title={getTitle()}
      initialIndex={'1'}
      items={tabs}
      forcedIndex={forcedIndex}
      setForcedIndex={setForcedIndex}
    />
  );

  function getTitle() {
    let title;
    if (id && id > 0) {
      if (data) {
        const { uuid, name } = data;
        title = uuid || name;
      }
    } else {
      title = literal('simso.create');
    }
    return title;
  }
}

export default Audiospook;
