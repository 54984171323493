import { request } from '../apiClient';
import { methods, PATHs } from '..';

function audioExportData(id, params) {
  const uri = PATHs.audiospookExport(id);

  return request( uri, methods.POST, params ).catch(err => {
    return Promise.reject(err);
  });
}

export { audioExportData };
