import React, { Fragment, useState, useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';

import checkPermission from '../../../../core/permissions/checkPermission';
import { history, storage, literal, toastUtils } from '../../../../core/utils';
import { audiospooksAPI, projectsAPI } from '../../../../core/requests';
import { Detail } from '../../../Common';
import { AudioSpookDetailUri } from '../../../../routes';
import { MapUpsert } from '../../../Common';
import { updateSimso } from './utils';

const DeviceConfig = props => {
  const { simso = {}, setSimso, editable, onSave } = props;

  const [projects, setProjects] = useState([]);
  useEffect(fetchProjects, []);

  const user = storage.getPersistentItem('user') || {};
  const imAdmin = user.role && user.role.name === 'ADMIN';

  const { id, name, project, projectId } = simso;

  return (
    checkPermission('audiospooks.configure') && (
      <Fragment>
        <Row>
          <Col md={12} className="mb-4">
            <h4>{literal('simso.header1')}</h4>
          </Col>
          <Detail
            inputData={{
              type: 'text',
              label: literal('simso.name'),
              name: 'name',
              value: name,
              placeholder: literal('simso.phName'),
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, name: value })}
          />
          <Detail
            inputData={{
              type: !!imAdmin ? 'select' : 'text',
              label: literal('simso.projectRelated'),
              name: 'project',
              value: !!imAdmin ? projectId : (project && project.name) || '-',
              options: projects,
              editable: !id,
              disabled: id,
            }}
            onChange={value => setSimso({ ...simso, projectId: value })}
          />
          <Detail
            inputData={{
              type: 'textarea',
              label: literal('simso.description'),
              name: 'description',
              value: simso.description,
              placeholder: literal('simso.phDescription'),
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, description: value })}
          />
          <MapUpsert
            editable={editable}
            location={simso.locationCenter}
            onChange={value => setSimso({ ...simso, locationCenter: value })}
            onlyAttr={true}
          />
          <Detail
            inputData={{
              type: 'textarea',
              label: literal('simso.location'),
              name: 'location',
              value: simso.location,
              placeholder: literal('simso.phLocation'),
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, location: value })}
          />
          <Detail
            inputData={{
              type: 'thumbnail',
              label: literal('simso.locationImage'),
              name: 'locationImage',
              value: simso.locationImage,
              thumbName: 'audiospook_location',
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, locationImage: value })}
          />

          {checkPermission('audiospooks.save') && (
            <Col md={12}>
              <Button
                color="primary"
                onClick={clickSave}
                className="float-right"
                disabled={!name || !name.length}
              >
                {literal('simso.save')}
              </Button>
            </Col>
          )}
        </Row>
      </Fragment>
    )
  );

  function fetchProjects() {
    projectsAPI.getProjects({ deleted: !!id }).then(elements => {
      const res =
        elements &&
        elements.map(({ name, id, deletedAt }) => {
          return (
            <option key={`project_${id}`} value={id}>
              {`${name} ${deletedAt ? ' (Eliminado)' : ''}`}
            </option>
          );
        });
      if (!simso.id && res.length) {
        setSimso({ ...simso, projectId: elements[0].id });
      }
      setProjects(res);
    });
  }

  function clickSave() {
    if (simso.locationCenter) {
      const { lat, lng } = simso.locationCenter;
      if (!lat || !lng) {
        return toastUtils.notify(literal('simso.errors.missingFieldsLatLng'));
      }
    }
    return updateSimso(simso).then(upsertSimso => {
      setSimso({ ...upsertSimso });
      if (!simso.id) {
        onSave && onSave({ ...upsertSimso, id: upsertSimso.id });
        history.push(AudioSpookDetailUri(upsertSimso.id));
        window.location.reload();
      }
    });
  }
};

export default DeviceConfig;
