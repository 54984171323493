import React from 'react';
import { Button } from 'reactstrap';
import { ProjectDetailUri } from '../../../routes';
import { history, storage } from '../../../core/utils';

const ProjectRowActions = ({ value }, onClickDelete) => {
  const user = storage.getPersistentItem('user') || {};
  const imAdmin = user.role && user.role.name === 'ADMIN';

  const id = value;
  const projectActions = [
    {
      icon: 'fa fa-eye',
      action: () => {
        history.push(ProjectDetailUri(id));
      }
    }
  ];

  if (imAdmin) {
    projectActions.push({
      icon: 'fa fa-trash',
      action: () => onClickDelete && onClickDelete(id)
    });
  }

  return (
    <div className="d-flex align-items-center">
      {projectActions.map((item, idx) => (
        <Button
          key={`actions_${idx}`}
          onClick={item.action}
          className={`${item.icon} bg-transparent text-primary rounded-circle btn-sm ml-1`}
          color={'primary'}
        />
      ))}
    </div>
  );
};

export default ProjectRowActions;
