import { request } from '../apiClient';
import { methods, PATHs } from '..';

function updateAudiospookVersion(id) {
  const uri = PATHs.updateAudiospookVersion(id);

  return request(uri, methods.POST)
    .catch(err => {
      return Promise.reject(err);
    });
}

export { updateAudiospookVersion };
