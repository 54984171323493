import { request } from '../apiClient';
import { methods, PATHs } from '..';

function configureAudiospook(id) {
  const uri = PATHs.configureAudiospook(id);

  return request(uri, methods.GET).catch(err => {
    return Promise.reject(err);
  });
}

export { configureAudiospook };
