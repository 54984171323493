import React from 'react';
import { Col } from 'reactstrap';
import styles from './MediaRow.module.scss';
import { literal } from '../../../../core/utils';
import * as moment from 'moment';
import { PATHs } from '../../../../core/requests';
import isMobile from 'ismobilejs';

const MediaRow = props => {
  const { name, createdAt } = props.data;
  const base = `${process.env.REACT_APP_API_URL}/api/`;

  return (
    name && (
      <Col className={`${styles.mediaCol}`} md={12}>
        <div className={styles.mediaContainer}>
          <div className={`${styles.dataContainer} ${styles.mobileColumn}`}>
            <span>
              {literal('simso.media.created')}:{' '}
              <b>{moment(createdAt).format('DD-MM-YYYY HH:mm:ss')}</b>
            </span>
            <span>
              {literal('general.name')}: <b>{name}</b>
            </span>

            <div className={styles.downloadLinks}>
              {isNotApple() && (
                <a
                  href={`${base}${PATHs.containerDownloadOctet(name)}`}
                  target="_blank"
                  className={styles.downloadLink}
                  download
                >
                  <i className={`icon-cloud-download`}></i>
                </a>
              )}

              <a
                href={`${base}${PATHs.containerDownload(name)}`}
                target="_blank"
                className={styles.downloadLink}
              >
                <i className={`icon-control-play`}></i>
              </a>
            </div>
          </div>
        </div>
      </Col>
    )
  );

  function isNotApple() {
    return !isMobile(window.navigator).apple.device;
  }
};

export default MediaRow;
