import React, { Fragment } from 'react';
import { Button, Row, Col } from 'reactstrap';
import * as siphash from 'siphash';

import checkPermission from '../../../../core/permissions/checkPermission';
import { history, storage, literal, toastUtils } from '../../../../core/utils';
import { Detail } from '../../../Common';
import { AudioSpookDetailUri } from '../../../../routes';
import { audiospooksAPI } from '../../../../core/requests';
import { updateSimso } from './utils';
import { app } from '../../../../config';

const DeviceConfig = props => {
  const { simso = {}, setSimso, editable, onSave } = props;

  const user = storage.getPersistentItem('user') || {};

  const imAdmin = user.role && user.role.name === 'ADMIN';
  const imClientAdmin = user.role && user.role.name === 'CLIENT_ADMIN';
  const internalUser = user.role && user.role.name.indexOf('CLIENT_') === -1;

  const { wifiSsid, wifiPass } = simso;
  const canConfig = simso.name || simso.id;

  return (
    checkPermission('audiospooks.configure') && (
      <Fragment>
        <Row className="mt-2">
          <Col md={12} className="mb-4">
            <h4>{literal('simso.header2')}</h4>
          </Col>

          <Detail
            inputData={{
              type: 'text',
              label: literal('simso.UUID'),
              name: 'uuid',
              value: simso.uuid,
              editable: !!imAdmin && editable,
              placeholder: literal('simso.phUuid'),
            }}
            onChange={value => updateDeviceParams('uuid', value)}
          />
          <Detail
            inputData={{
              type: 'text',
              label: literal('simso.password'),
              name: 'plainPass',
              value: simso.plainPass,
              editable: !!imAdmin && editable,
              placeholder: literal('simso.phPassword'),
              group: {
                type: 'button',
                icon: 'icon-key',
                action: generatePass,
              },
            }}
            onChange={value => updateDeviceParams('plainPass', value)}
          />
          {!!internalUser && editable ? (
            <Detail
              inputData={{
                md: 6,
                labelSize: 6,
                type: 'select',
                label: literal('simso.range'),
                name: 'range',
                value: simso.range,
                placeholder: literal('simso.phRange'),
                editable: editable,
                options: getConfigRange(),
              }}
              onChange={value => setSimso({ ...simso, range: value })}
            />
          ) : (
            <Detail
              inputData={{
                md: 6,
                labelSize: 6,
                type: 'text',
                label: literal('simso.range'),
                name: 'range',
                value: literal(app.RANGES.find(r => r.value === simso.range).name),
                editable: false,
                placeholder: literal('simso.phRange'),
              }}
            />
          )}
          <Detail
            inputData={{
              md: 6,
              labelSize: 6,
              type: 'number',
              label: literal('simso.calibration'),
              name: 'calibration',
              value: simso.calibration,
              placeholder: literal('simso.phCalibration'),
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, calibration: value })}
          />

          <Detail
            inputData={{
              type: 'text',
              label: literal('simso.wifiSsid'),
              name: 'wifiSsid',
              value: simso.wifiSsid,
              placeholder: literal('simso.phWifiSsid'),
              editable: editable || imClientAdmin,
            }}
            onChange={value => setSimso({ ...simso, wifiSsid: value })}
          />
          <Detail
            inputData={{
              type: 'text',
              label: literal('simso.wifiPass'),
              name: 'wifiPass',
              value: simso.wifiPass,
              placeholder: literal('simso.phWifiPass'),
              editable: editable || imClientAdmin,
            }}
            onChange={value => setSimso({ ...simso, wifiPass: value })}
          />

          <Col md={12} className="mb-4 mt-4">
            <h6>{literal('simso.autoRecording.title')}</h6>
          </Col>

          <Detail
            inputData={{
              md: 4,
              labelSize: 4,
              type: 'switch',
              yes: literal('simso.autoRecording.stateYes'),
              label: literal('simso.autoRecording.state'),
              name: 'autoRecording',
              value: simso.autoRecording,
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, autoRecording: value })}
          />
          <Detail
            inputData={{
              md: 4,
              labelSize: 4,
              type: 'select',
              label: literal('simso.autoRecording.type'),
              name: 'autoRecordingType',
              value: simso.autoRecordingType,
              editable: !!imAdmin && editable,
              options: getRecordingTypes(),
            }}
            onChange={value => setSimso({ ...simso, autoRecordingType: value })}
          />
          <Detail
            inputData={{
              md: 4,
              labelSize: 4,
              type: 'number',
              label: literal('simso.autoRecording.value'),
              name: 'autoRecordingValue',
              value: simso.autoRecordingValue,
              placeholder: literal('simso.autoRecording.phValue'),
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, autoRecordingValue: value })}
          />
          <Detail
            inputData={{
              type: 'textarea',
              label: literal('simso.autoRecording.emails'),
              name: 'autoRecordingEmails',
              value: simso.autoRecordingEmails,
              placeholder: literal('simso.autoRecording.phEmails'),
              editable: !!imAdmin && editable,
            }}
            onChange={value => setSimso({ ...simso, autoRecordingEmails: value })}
          />

          <Col md={12}>
            <Button
              color={'primary'}
              onClick={clickConfig}
              className="float-right mb-5"
              disabled={!canConfig}
            >
              {literal(`simso.configure`)}
            </Button>
          </Col>
        </Row>
      </Fragment>
    )
  );

  function updateDeviceParams(attr, value) {
    if (!simso.changeDevice) {
      setSimso({ ...simso, [attr]: value, changeDevice: true });
    } else {
      setSimso({ ...simso, [attr]: value });
    }
  }

  function generatePass() {
    const key = process.env.REACT_APP_SIPHASH_KEY;
    const message = `${new Date().getTime()}${key}`;
    setSimso({ ...simso, plainPass: siphash.hash_hex(key, message) });
  }

  function clickConfig() {
    const data = imClientAdmin ? { id: simso.id, wifiSsid, wifiPass } : simso;
    if (internalUser) {
      if (!data.autoRecordingType) data.autoRecordingType = app.AUTO_RECORDING_TYPES[0];
      if (!data.range) data.range = app.RANGES[1].value;
      if (simso.calibration && simso.oldCalibration && simso.range && simso.oldRange) {
        data.changeCalibration =
          simso.calibration != simso.oldCalibration || simso.range !== simso.oldRange;
      }
    }

    return updateSimso(data)
      .then(upsertSimso => {
        setSimso({ ...upsertSimso });
        const devices = upsertSimso && upsertSimso.devices;
        if (simso.id && devices && devices.length) {
          audiospooksAPI
            .configureAudiospook(simso.id)
            .then(() => {
              toastUtils.success(literal('simso.configureSuccess'));
              window.location.reload();
            })
            .catch(() => toastUtils.notify(literal('general.errors.fetching')));
        } else if (simso.id) {
          onSave && onSave({ ...upsertSimso, id: simso.id });
          history.push(AudioSpookDetailUri(simso.id));
          window.location.reload();
        }
      })
      .catch(err => {
        toastUtils.notify(err.name);
        setSimso({ ...simso });
      });
  }

  function getRecordingTypes() {
    return app.AUTO_RECORDING_TYPES.map(type => {
      return (
        <option key={type} value={type}>
          {type}
        </option>
      );
    });
  }

  function getConfigRange() {
    return app.RANGES.map(({ name, value }) => {
      return (
        <option key={value} value={value}>
          {literal(name)}
        </option>
      );
    });
  }
};

export default DeviceConfig;
