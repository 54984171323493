import React from 'react';
import { Col } from 'reactstrap';
import common from './common.module.scss';
import { history, imgSource } from '../../../../core/utils';
import { ProjectDetailUri } from '../../../../routes';
import placeholder from '../../../../assets/img/placeholder.jpg';

const ProjectRow = props => {
  const { id, name, description, image } = props.data;
  const url = imgSource(image, null, image || placeholder);

  return (
    <Col
      className={common.colContainer}
      md={12}
      onClick={() => {
        history.push(ProjectDetailUri(id));
      }}
    >
      <div className="d-flex align-items-center flex-start">
        <div className={`${common.avatarContainer} `}>
          <img src={url} className={common.avatar} alt={'avatar'} />
        </div>

        <div className={`ml-3`}>
          <b>{name}</b>
          <p>{description}</p>
        </div>
      </div>
    </Col>
  );
};

export default ProjectRow;
