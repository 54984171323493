import React from 'react';
import { history } from '../../../../../core/utils';
import style from './ColItemId.module.css';

const ColItemId = ({ value, uriGenerator }) => {
  const id = value;
  const action = () => {
    history.push(uriGenerator(id));
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div onClick={() => action()} className={style.title}>
        {value}
      </div>
    </div>
  );
};

export default ColItemId;
