import React, { useEffect, useState } from 'react';
import ProjectsList from '../List/ProjectsList';
import { ProjectDetailUri } from '../../../routes';
import { projectsAPI } from '../../../core/requests';
import { Redirect } from 'react-router';
import { Loading } from '../../Common';
import { storage } from '../../../core/utils';

export default function Projects(props) {
  const [projects, setProjects] = useState();
  const user = storage.getPersistentItem('user') || {};
  const imUser =
    user.role &&
    (user.role.name === 'CLIENT_ADMIN' || user.role.name === 'CLIENT_USER');

  useEffect(() => {
    if (imUser) {
      projectsAPI
        .getProjects({ filter: { fields: ['id'] } })
        .then(projects => setProjects(JSON.parse(JSON.stringify(projects))));
    }
  }, []);

  if (!imUser) {
    return <ProjectsList projects={projects} />;
  } else {
    if (projects) {
      if (!projects.length || projects.length > 1) return <ProjectsList projects={projects} />;
      else {
        return <Redirect to={ProjectDetailUri(projects[0].id)} />;
      }
    } else {
      return <Loading />;
    }
  }
}
