import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker
} from 'react-google-maps';

import styles from './MapUpsert.module.scss';
import Detail from '../Detail';
import { literal } from '../../../core/utils';

const apiKey = process.env.REACT_APP_MAPS_API_KEY;
const defaultZoom = 11;
const defaultCenter = { lat: 39.0081642, lng: 1.3431961 };

const GoogleMapsComponent = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        center={props.center}
        defaultZoom={defaultZoom}
        defaultCenter={props.center}
      >
        <Marker position={props.center} />

        {props.markers.map((marker, idx) => {
          return (
            <Marker
              key={idx}
              position={marker}
              label={marker.name}
              opacity={0.75}
            />
          );
        })}
      </GoogleMap>
    );
  })
);

const MapUpsert = props => {
  const { editable, markers, onlyAttr = false } = props;
  let location = props.location || (onlyAttr ? {} : defaultCenter);

  const setAttribute = (value, attribute) => {
    if (value) {
      mapLocation[attribute] = +value;
      props.onChange && props.onChange(mapLocation);
      setMapLocation(JSON.parse(JSON.stringify(mapLocation)));
    }
  };
  const [mapLocation, setMapLocation] = useState(location);

  return (
    mapLocation && (
      <Col md={12}>
        <Row className={styles.mapContainer}>
          {getAttributes()}
          {!onlyAttr && (
            <Col sm={12}>
              <GoogleMapsComponent
                key="map"
                center={mapLocation}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                markers={markers || []}
              />
            </Col>
          )}
        </Row>
      </Col>
    )
  );

  function getAttributes() {
    return (
      <Col sm={12}>
        <Row>
          <Detail
            inputData={{
              editable,
              md: 6,
              labelSize: 6,
              type: 'number',
              label: literal('maps.latitude'),
              name: 'latitude',
              value: mapLocation.lat,
              placeholder: literal('maps.latitude')
            }}
            onChange={e => setAttribute(e, 'lat')}
          />
          <Detail
            inputData={{
              editable,
              md: 6,
              labelSize: 6,
              type: 'number',
              label: literal('maps.longitude'),
              name: 'longitude',
              value: mapLocation.lng,
              placeholder: literal('maps.longitude')
            }}
            onChange={e => setAttribute(e, 'lng')}
          />
        </Row>
      </Col>
    );
  }
};

export default MapUpsert;
