import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button, Card, CardBody } from 'reactstrap';
import { usersAPI } from '../../core/requests';
import { history, saveUserToken, literal, toastUtils } from '../../core/utils';
import FormInput from './inputs/FormInput';
import logoImg from '../../assets/img/brand/logo.png';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [canSubmit, setcanSubmit] = useState(false);

  useEffect(() => {
    setcanSubmit(email.length && password.length);
  }, [email, password]);

  function forgotPassword() {
    history.push('/forgot');
  }

  function submitForm(e) {
    e.preventDefault();
    usersAPI
      .login({ email, password, rememberMe: true })
      .then(res => {
        if (res) {
          saveUserToken(res.id, true);
          history.push('/home');
        }
      })
      .catch(err => {
        toastUtils.notify(literal('login.badLogin'));
        return Promise.reject(err);
      });
  }

  return (
    <div className="app d-flex h-100 v-100 justify-content-center align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col sm={8}>
            <Card className="p-4">
              <CardBody>
                <img src={logoImg} alt="logo" className="w-50" />
                <h3 className="pt-2 pb-2">{literal('login.subtitle')}</h3>
                <Form onSubmit={submitForm}>
                  <FormInput
                    inputAttributes={{
                      icon: 'icon-user',
                      type: 'email',
                      name: 'email',
                      id: 'emailInput',
                      initialValue: email,
                      placeholder: literal('login.placeholder'),
                      formText: literal('login.helper'),
                      formFeedback: literal('login.invalidEmail'),
                    }}
                    valueValidated={value => {
                      setEmail(value);
                    }}
                    valueInvalidated={() => {
                      setEmail('');
                    }}
                  />
                  <FormInput
                    inputAttributes={{
                      icon: 'icon-lock',
                      type: 'password',
                      name: 'password',
                      id: 'passwordInput',
                      initialValue: password,
                      placeholder: '******',
                      formFeedback: literal('login.invalidPassword'),
                    }}
                    valueValidated={value => {
                      setPassword(value);
                    }}
                    valueInvalidated={() => {
                      setPassword('');
                    }}
                  />
                  <a
                    onClick={forgotPassword}
                    style={{
                      fontSize: '12px',
                      color: '#3460a1',
                      cursor: 'pointer',
                    }}
                  >
                    {literal('login.forgot')}
                  </a>

                  <Row className="mx-0 pt-4">
                    <Button color="primary" disabled={!canSubmit}>
                      {literal('login.login')}
                    </Button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
