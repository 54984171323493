import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Col, Row } from 'reactstrap';
import styles from './DetailForm.module.scss';
import Detail from '../Detail/Detail';

function DetailForm(props) {
  const {
    label,
    labelSize = '3',
    editable = true,
    md = '12'
  } = props.inputData;
  const { inputs } = props;

  const htmlName = `${inputs[0].name}-input`;

  const [formData, setFormData] = useState({});

  useEffect(() => {
    props.onChange(formData);
  }, [formData]);

  return (
    <Col md={md}>
      <FormGroup row>
        {label && (
          <Col md={labelSize} className={styles.labelCotainer}>
            <Label className={styles.detailLabel} htmlFor={htmlName}>
              {label}
            </Label>
          </Col>
        )}
        <Col md={9}>
          <Row>
            {inputs.map(({ type, placeholder, name, value }, idx) => {
              return (
                <Detail
                  key={`${name}_${idx}`}
                  inputData={{
                    label: placeholder,
                    labelSize: 12,
                    type,
                    placeholder,
                    name,
                    value,
                    editable,
                    md: 12 / inputs.length
                  }}
                  onChange={value => valuesDidChange(name, value)}
                />
              );
            })}
          </Row>
        </Col>
      </FormGroup>
    </Col>
  );

  function valuesDidChange(name, value) {
    setFormData({ ...formData, [name]: value });
  }
}

export default DetailForm;
