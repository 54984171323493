import React, { Fragment, useState } from 'react';
import * as moment from 'moment';
import { literal, toastUtils } from '../../../core/utils';
import { autenticatedURI } from '../../../core/requests/apiClient';
import { audiospooksAPI, PATHs } from '../../../core/requests';
import { Col, Row, Button } from 'reactstrap';
import Detail from '../../Common/Detail';
import { app } from '../../../config';

function AudiospookDownloadData(props) {
  const { id } = props;
  const [initDate, setInitDate] = useState(getDate());
  const { DURATION, INTEGRATION } = app.AUDIO_DATA;

  const [duration, setDuration] = useState(DURATION[0].value);
  const [integrationTime, setIntegrationTime] = useState(INTEGRATION[0].value);
  const [downloading, setDownloading] = useState(false);

  return (
    <Fragment>
      <Row className="mb-3 p-3">
        <Col md={6}>
          <Row>
            <Detail
              inputData={{
                type: 'date',
                label: literal('simso.date'),
                name: 'date',
                value: initDate,
                placeholder: literal('simso.date')
              }}
              onChange={value => setInitDate(getDate(value))}
            />
            <Detail
              inputData={{
                type: 'select',
                label: literal('simso.duration'),
                name: 'duration',
                value: duration,
                options: getOptions(DURATION)
              }}
              onChange={value => setDuration(value)}
            />
            <Detail
              inputData={{
                type: 'select',
                label: literal('simso.integrationTime'),
                name: 'integrationTime',
                value: integrationTime,
                options: getOptions(INTEGRATION)
              }}
              onChange={value => setIntegrationTime(value)}
            />
            <Col>
              <Button
                color="primary"
                onClick={submitForm}
                disabled={downloading}
                className="float-right"
              >
                {literal('simso.download')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );

  function submitForm() {
    if (id && id > 0) {
      setDownloading(true);
      toastUtils.info({
        message: literal('simso.downloading'),
        type: 'info',
        options: { hideProgressBar: true, autoClose: false }
      });

      const format = 'YYYY-MM-DD HH:mm';
      const utcDate = moment(initDate, format)
        .utc()
        .format(format);
      const data = { initDate: utcDate, duration, integrationTime };

      audiospooksAPI.audioExportData(id, data).then(file => {
        setDownloading(false);
        if (file && file.document) {
          const URI = autenticatedURI(PATHs.audiospookDownloadExport);
          window.location.href = `${URI}&documentID=${file.document}`;
        }
      });
    }
  }
}

function getDate(date) {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

function getOptions(elements) {
  return elements.map(({ name, value }, idx) => {
    return (
      <option key={idx} value={value}>
        {name}
      </option>
    );
  });
}

export default AudiospookDownloadData;
