import { request } from '../apiClient';
import { methods, PATHs } from '..';

const userProjects = data => {
  const { userProjects } = PATHs;
  return request(userProjects(data.id), methods.PATCH, data.projects).catch(
    err => {
      return Promise.reject(err);
    }
  );
};

export { userProjects };
