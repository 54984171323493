import React, { useState, useEffect, Fragment } from 'react';
import {
  FormGroup,
  Label,
  Input,
  CustomInput,
  FormText,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import styles from './Detail.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Detail.module.scss';
import Thumbnail from '../Thumbnail';
import * as moment from 'moment';

const Detail = props => {
  const {
    type = 'text',
    label,
    labelSize = '3',
    md = '12',
    help,
    name,
    placeholder,
    value,
    options,
    disabled,
    editable = true,
    thumbName,
    group = null,
    colStyle = '',
  } = props.inputData;

  const [detailValue, setDetailValue] = useState(value);
  useEffect(() => {
    if (name === 'plainPass') setDetailValue(value);
  });
  const htmlName = `${name}-input`;
  const valueSize = Math.max(0, 12 - labelSize);

  const input = getInputByType();

  return (
    <Col md={md} className={`${styles.detailContainer} ${colStyle}`}>
      <FormGroup row>
        {label && (
          <Col md={labelSize}>
            <Label className={styles.detailLabel} htmlFor={htmlName}>
              {label}
            </Label>
          </Col>
        )}

        {editable && (
          <Fragment>
            {type === 'date' && (
              <Col className={`col-md-${!label ? '12' : valueSize}`}>{input}</Col>
            )}
            {type !== 'date' && (
              <Col className={`col-md-${!label ? '12' : valueSize}`}>
                {!!group && (
                  <InputGroup>
                    {input}
                    <InputGroupAddon addonType="append">
                      {group.type === 'button' && (
                        <Button color={group.color || 'primary'} onClick={group.action}>
                          {!!group.icon && <i className="icon-key"></i>}
                        </Button>
                      )}
                    </InputGroupAddon>
                  </InputGroup>
                )}
                {!group && input}
              </Col>
            )}
          </Fragment>
        )}

        {!editable && (
          <Col className={`col-md-${!label ? '12' : valueSize}`}>
            <span id={htmlName}>{input}</span>
          </Col>
        )}
      </FormGroup>
    </Col>
  );

  function handleInputChange({ target }) {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setDetailValue(value);
    props.onChange && props.onChange(value);
  }

  function handleDateChange(date) {
    props.onChange && props.onChange(date);
  }

  function handleTimeChange(date) {
    const value = moment(date).format('HH:mm');
    setDetailValue(value);
    props.onChange && props.onChange(value);
  }

  function getInputByType() {
    let res;
    if (type === 'date') res = getDateType();
    else if (type === 'time') res = getTimeType();
    else if (type === 'thumbnail') res = getThumbnailType();
    else if (type === 'switch') res = getSwitchType();
    else if (type === 'duration') res = getDurationType();
    else res = getDefaultType();
    return res;

    function getTimeType() {
      return (
        <DatePicker
          className={`form-control ${styles.dateInput} font-weight-light`}
          selected={moment(detailValue, 'HH:mm').toDate()}
          onChange={handleTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={1}
          dateFormat={'HH:mm'}
          timeFormat={'HH:mm'}
          placeholderText={placeholder || ''}
          popperPlacement={'top-end'}
        />
      );
    }

    function getDateType() {
      return (
        <DatePicker
          className={`form-control ${styles.dateInput} font-weight-light`}
          selected={Date.parse(value)}
          onChange={handleDateChange}
          showTimeSelect
          timeIntervals={1}
          dateFormat={'yyyy/MM/dd HH:mm'}
          timeFormat={'HH:mm'}
          maxDate={Date.now()}
          placeholderText={placeholder || ''}
          popperPlacement={'top-end'}
        />
      );
    }

    function getThumbnailType() {
      return (
        <Thumbnail
          src={value}
          onLoaded={value => props.onChange && props.onChange(value)}
          editable={editable}
          name={thumbName}
        />
      );
    }

    function getSwitchType() {
      return (
        <CustomInput
          type="switch"
          defaultChecked={detailValue || false}
          label={props.inputData.yes}
          className={'font-weight-light'}
          key={htmlName}
          id={htmlName}
          name={htmlName}
          value={detailValue}
          onChange={handleInputChange}
          disabled={disabled}
        />
      );
    }

    function getDurationType() {
      const durationsMap = {
        '10 min': moment.duration(10, 'minutes').as('hours'),
        '20 min': moment.duration(20, 'minutes').as('hours'),
        '40 min': moment.duration(40, 'minutes').as('hours'),
        '1 h': 1,
        '4 h': 4,
      };

      const durationOptions = Object.keys(durationsMap).map((duration, idx) => (
        <option key={`duration_${idx}`} value={durationsMap[duration]}>
          {duration}
        </option>
      ));

      const handleDurationChange = ({ target }) => {
        props.onChange && props.onChange(target.value);
      };
      return (
        <Fragment>
          <Input
            className={'font-weight-light'}
            key={'duration'}
            type={'select'}
            id={'duration'}
            name={'duration'}
            value={value}
            onChange={handleDurationChange}
            disabled={disabled}
          >
            {durationOptions}
          </Input>
        </Fragment>
      );
    }

    function getDefaultType() {
      return editable || type === 'select' ? (
        <Fragment>
          <Input
            className={'font-weight-light'}
            key={htmlName}
            type={type}
            id={htmlName}
            name={htmlName}
            placeholder={placeholder}
            value={detailValue || ''}
            onChange={handleInputChange}
            disabled={disabled}
          >
            {options}
          </Input>
          {help && <FormText className="help-block">{help}</FormText>}
        </Fragment>
      ) : (
        <span id={htmlName}>{detailValue || '-'}</span>
      );
    }
  }
};

export default Detail;
