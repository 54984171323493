import React, { useState, Fragment } from 'react';
import { Button, Modal, Row, Col, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { audiospooksAPI, projectsAPI } from '../../../../../core/requests';
import { literal, toastUtils } from '../../../../../core/utils';

const ModalRecord = props => {
  const { id, disabled = true, withMargin = true } = props;
  const [isOpen, setIsOpen] = useState(false);

  const clickRecord = () => {
    const call = props.imProject
      ? projectsAPI.recordSimsos(id)
      : audiospooksAPI.recordAudiospook(id);

    call
      .then(() => {
        toastUtils.success(literal('simso.recordSuccess'));
        setIsOpen(false);
      })
      .catch(err => {
        toastUtils.notify(err.message);
      });
  };

  return (
    <Fragment>
      <div>
        <Button
          className={withMargin ? 'mb-3' : ''}
          color={'primary'}
          disabled={disabled}
          onClick={() => setIsOpen(true)}
        >
          {literal(`simso.record`)}
        </Button>
      </div>

      <Modal isOpen={isOpen} size={'lg'}>
        <ModalHeader>{literal('simso.record')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="p-3" md={12}>
              {props.imProject ? literal('project.recordBody') : literal('simso.recordBody')}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => setIsOpen(false)}>
            {literal('general.close')}
          </Button>
          <Button color="primary" onClick={clickRecord}>
            {literal('general.apply')}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ModalRecord;
