import React, { useState } from 'react';
import styles from './Avatar.module.scss';
import placeholder from '../../../assets/img/placeholder.jpg';
import { literal, imgSource } from '../../../core/utils';
import { containerAPI } from '../../../core/requests';
import Loading from '../Loading';
import * as get from 'lodash.get';

const Avatar = props => {
  let { src } = props;
  const {
    alt = 'avatar',
    editable = true,
    xl = false,
    name = 'default',
    onLoaded
  } = props;

  if (!src) src = placeholder;
  else if (typeof src === 'object' && !!src.result) {
    src = get(src, 'result.files.file[0].name');
  }

  let url = imgSource(src, null, src);
  if (src === placeholder) url = src;

  const [avatar, setAvatar] = useState(url);
  const [loader, setLoader] = useState(false);

  const handleChange = event => {
    setLoader(true);
    const { target } = event;
    if (target && target.files) {
      const [file] = target.files;

      const reader = new FileReader();
      reader.onload = evt => {
        setAvatar(evt.target.result);
        const ext = file.name.split('.').pop();
        const fileName = `${name}-avatar-${new Date().getTime()}.${ext}`;
        containerAPI.containerUpload(file, fileName).then(avatarName => {
          onLoaded && onLoaded(avatarName);
          setLoader(false);
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className={`${styles.avatarContainer} ${loader &&
        styles.loader} ${editable && styles.editable} ${xl && styles.xl}`}
    >
      <img className={styles.avatarImg} src={avatar} alt={alt} />
      {editable && (
        <div className={styles.avatarBottom}>
          {loader ? (
            <Loading type={'border'} />
          ) : (
            <div className={styles.avatarText}>
              {literal('general.change')}
              <input
                type="file"
                accept="image/*"
                className={styles.inputFile}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Avatar;
