import React, { useState } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardFooter
} from 'reactstrap';

import { usersAPI } from '../../../core/requests';
import { history } from '../../../core/utils';
import { literal } from '../../../core/utils';
import Detail from '../../Common/Detail';
import { validEmail } from '../../../core/utils/validation';

const Forgot = props => {
  const [email, setEmail] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const sendMail = () => {
    usersAPI
      .forgotPassword({ email })
      .then(res => {
        setCanSubmit(false);
        setEmailSent(true);
      })
      .catch(() => {
        setCanSubmit(false);
        setEmailSent(true);
      });
  };

  const backLogin = () => {
    history.push('/login');
  };

  return (
    <div className="app d-flex h-100 v-100 justify-content-center align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col sm={8}>
            <Card className="p-4">
              <CardBody>
                <h2 className="pb-3">{literal('session.forgot.title')}</h2>
                <h5 className="pb-2">{literal('session.forgot.subtitle')}</h5>

                {emailSent ? (
                  <p>
                    {literal('session.forgot.success')}: {email}
                  </p>
                ) : (
                  <Row className="mt-4">
                    <Detail
                      inputData={{
                        md: 12,
                        type: 'text',
                        name: 'lastname',
                        value: email,
                        placeholder: literal('user.email')
                      }}
                      onChange={value => {
                        setEmail(value);
                        setCanSubmit(validEmail(value));
                      }}
                    />
                  </Row>
                )}
              </CardBody>
              <CardFooter>
                <Button
                  color={'primary'}
                  disabled={!canSubmit}
                  className="float-right"
                  onClick={sendMail}
                >
                  {literal('session.forgot.button')}
                </Button>
                <Button
                  color={'default'}
                  className="float-right"
                  onClick={backLogin}
                >
                  {literal('session.forgot.backLogin')}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Forgot;
