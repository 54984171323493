import { crudRequest } from '../apiClient';
import { methods, PATHs } from '..';

const deleteUser = id => {
  const { userDelete } = PATHs;
  const uri = userDelete(id);

  return crudRequest({ uri, method: methods.DELETE }).catch(err =>
    {return Promise.reject(err);}
  );
};

export { deleteUser };
