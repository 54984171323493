import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormFeedback,
  FormText
} from 'reactstrap';

const validState = 'has-success';
const invalidState = 'has-danger';

function FormInput(props) {
  const {
    icon,
    type,
    name,
    id,
    placeholder,
    initialValue,
    formFeedback,
    formText,
    text,
    label
  } = props.inputAttributes;

  const { style } = props;
  let defaultValue = initialValue || '';
  if (type === 'checkbox') {
    defaultValue = false;
  }
  const [value, setValue] = useState(defaultValue);
  const [valid, setValid] = useState(initialValue ? validState : '');

  const { valueValidated, valueInvalidated } = props;

  useEffect(() => {
    if (valid === validState) valueValidated(value);
    else valueInvalidated();
  }, [value]);

  return type === 'image' ? (
    <FormGroup style={style}>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          {icon ? (
            <InputGroupText>
              <i className={icon} />
            </InputGroupText>
          ) : (
            label
          )}
        </InputGroupAddon>
        <div className="d-flex justify-content-center align-items-center ml-2 mr-2">

          <input type="file" onchange="readURL(this);" />
        </div>
        {text && text}
      </InputGroup>

      {formFeedback && <FormFeedback>{formFeedback}</FormFeedback>}
      {formText && <FormText>{formText}</FormText>}
    </FormGroup>
  ) : (
    <FormGroup style={style}>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          {icon ? (
            <InputGroupText>
              <i className={icon} />
            </InputGroupText>
          ) : (
            label
          )}
        </InputGroupAddon>
        <Input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          checked={value}
          valid={valid === validState}
          invalid={valid === invalidState}
          onChange={e => {
            validateInput(e);
            handleChange(e);
          }}
        />
        {text && text}
      </InputGroup>

      {formFeedback && <FormFeedback>{formFeedback}</FormFeedback>}
      {formText && <FormText>{formText}</FormText>}
    </FormGroup>
  );

  function validateInput(event) {
    const { target } = event;
    const inputValue =
      target.type === 'checkbox' ? target.checked : target.value;

    switch (target.type) {
      case 'email':
        validateEmail(inputValue);
        break;
      case 'password':
        validatePassword(inputValue);
        break;
      default:
        defaultValidation();
        break;
    }
  }

  function validateEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let inputValidState = invalidState;
    if (emailRex.test(value)) {
      inputValidState = validState;
    }
    setValid(inputValidState);
  }

  function validatePassword(value) {
    let inputValidState = invalidState;
    if (value.length) {
      inputValidState = validState;
    }
    setValid(inputValidState);
  }

  function defaultValidation() {
    setValid(validState);
  }

  function handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setValue(value);
  }
}

export default FormInput;
