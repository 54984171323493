import React, { useState, useEffect } from 'react';

import { projectsAPI } from '../../../core/requests';
import Tabs from '../../Common/tabs/Tabs';
import ProjectInfo from './ProjectInfo';
import ElementsList from '../../Common/ElementsList';
import { literal } from '../../../core/utils';
import checkPermission from '../../../core/permissions/checkPermission';
import { ModalRecord } from '../../Audiospooks/Detail/Info/modals';

const Project = props => {
  const { id } = props.match.params;
  const [data, setData] = useState();

  useEffect(() => {
    if (id && id > 0)
      projectsAPI
        .getProject(id)
        .then(setData)
        .catch(() => setData({}));
    else setData({});
  }, []);

  const tabs = [];

  if (checkPermission('projects.tabs.info')) {
    tabs.push(
      {
        name: literal('project.info'),
        component: <ProjectInfo data={data} onCreate={setData} />,
      },
      {
        name: literal('project.users'),
        component: <ElementsList elements={data} type={'users'} />,
        disabled: !data || !data.id,
      }
    );
  }
  tabs.push({
    name: literal('project.simsos'),
    component: <ElementsList elements={data} type={'audiospooks'} />,
    disabled: !data || !data.id,
  });

  return (
    <Tabs
      title={id && id > 0 ? data && data.name : literal('project.create')}
      initialIndex={
        id && id > 0 && data && data.audiospooks && data.audiospooks.length ? `${tabs.length}` : '1'
      }
      items={tabs}
      headerButtons={
        checkModal() && (
          <ModalRecord
            id={data.id}
            disabled={checkDisabled()}
            withMargin={false}
            imProject={true}
          />
        )
      }
    />
  );

  function checkModal() {
    return data && data.id > 0 && checkPermission(`audiospooks.sendAudio`);
  }

  function checkDisabled() {
    let value = true;
    if (data.audiospooks) {
      value = !data.audiospooks.some(simso => simso.activated);
    }
    return value;
  }
};

export default Project;
