import styled from 'styled-components';

export const Table = styled.div`
  display: block;
  border-radius: 0 0 5px 5px;
  border: solid 1px #ddd;
  overflow: auto;
  flex-grow: 1;
  height: 1px;
  min-height: 105px;
`;

const RowBase = styled.div`
  display: flex;
  border-bottom: solid 1px #ddd;

  :last-child {
    border-bottom: 0;
  }
`;

export const Row = styled(RowBase)`
  background: white;
  color: rgba(100, 105, 112, 1);
`;

export const HeaderRow = styled(RowBase)`
  background: rgba(234, 239, 247, 1) !important;
  color: rgba(100, 105, 112, 1);
`;

export const Pagination = styled(RowBase)`
  background: rgba(42, 117, 146, 1);
  color: white;
`;

export const Cell = styled.div`
  padding: 0.6rem;

  :last-child {
    border-right: 0;
  }
`;

export const Header = styled(Cell)``;

export const Button = styled.button`
  padding: 0.5rem 0.7rem;
  background: white;
  border-radius: 5px;
  cursor: pointer;

  :disabled {
    opacity: 0.3;
  }
`;

export const Select = styled.select`
  appearance: none;
  background: white;
  border: 0;
  margin: 0;
  color: black;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  border: 0;
  cursor: pointer;
`;

export const Input = styled.input`
  padding: 0.5rem 0.7rem;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 100%;
`;

export const Emoji = styled.span`
  margin: 0 0.3rem;
  display: inline-block;
  transform: scale(1.4);
`;
