import { request } from '../apiClient';
import { methods, PATHs } from '..';

const updateProject = data => {
  const { projects, projectDetail } = PATHs;
  const uri = data.id ? projectDetail(data.id) : projects;
  return request(uri, methods.PATCH, data).catch(err => {
    throw err;
  });
};

export { updateProject };
