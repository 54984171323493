import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import common from './common.module.scss';
import AudiospookGraph from '../../../Audiospooks/Detail/Graph/AudiospookGraph';
import { history, imgSource, literal } from '../../../../core/utils';
import { AudioSpookDetailUri } from '../../../../routes';
import { audiospooksAPI } from '../../../../core/requests';
import placeholder from '../../../../assets/img/placeholder.jpg';
import DBLabel from '../../DBLabel';
import Loading from '../../Loading';

const AudiospookRow = props => {
  const { data, historySearch } = props;
  const { id, name, uuid, image } = data;
  const url = imgSource(image, null, image || placeholder);
  let timer;

  const [audioData, setAudioData] = useState();

  const fetchData = () => {
    audiospooksAPI.getAudioLastData(id).then(data => {
      if (data) setAudioData(data);
      else clearInterval(timer);
    });
  };
  if (!audioData) fetchData();

  useEffect(() => {
    timer = setInterval(fetchData, 10000);
    return () => clearInterval(timer);
  }, []);

  return typeof audioData === 'undefined' ? (
    <Loading />
  ) : (
    <Col
      className={common.colContainer}
      md={12}
      onClick={() => {
        history.push({
          pathname: AudioSpookDetailUri(id),
          search: historySearch && `?${historySearch}`,
        });
      }}
    >
      <Row className="align-items-center m-0">
        <Col md={1} className={common.avatarContainer}>
          <img src={url} className={common.avatar} />
        </Col>

        <Col md={1}>
          {!data.activated && <p><small>{literal('simso.noDataInactive')}</small></p>}
          <p>
            <b>{name || uuid}</b>
          </p>
        </Col>

		    {audioData.dBA >= 0 && (
        <>
          <Col mmd={2} className={common.audioDataDbA}>
            <DBLabel size="large" value={audioData.dBA} label={'dBA'} />
          </Col>
          <Col md={2}>
            <Row className={common.audioDataDb}>
              <DBLabel md={12} value={audioData.dBC} label={'dBC'} />
              <DBLabel md={12} value={audioData.dBZ} label={'dBZ'} />
            </Row>
          </Col>
        </>
        )}

        <Col md={5}>
          <AudiospookGraph data={audioData} />
        </Col>
      </Row>
    </Col>
  );
};

export default AudiospookRow;
