import React, { useState } from 'react';
import CrudList from '../../Common/ListItems/CrudList';
import AudioSpookRowActions from './AudioSpookRowActions';
import { AudioSpookDetailUri } from '../../../routes';
import { PATHs, audiospooksAPI } from '../../../core/requests';
import { storage, literal } from '../../../core/utils';

const AudiospookList = props => {
  const user = storage.getPersistentItem('user') || {};
  const imAdmin = user.role && user.role.name === 'ADMIN';

  const [isOpen, setIsOpen] = useState(false);
  const [instance, setInstance] = useState();
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      Header: literal('simso.uuid'),
      id: 'uuid',
      accessor: 'uuid',
      minWidth: 300
    },
    {
      Header: literal('general.name'),
      id: 'name',
      accessor: 'name',
      minWidth: 300
    },
    {
      Header: literal('project.single'),
      id: 'project.name',
      accessor: ({ project }) => {
        let value;
        if (project) {
          const { deletedAt, name } = project;
          if (deletedAt) value = `${name} (Eliminado)`;
          else value = name;
        } else value = literal('simso.noProject');
        return value;
      },
      width: 200,
      canSortBy: false
    },
    {
      Header: literal('simso.activated'),
      id: 'activated',
      accessor: ({ activated, devices }) => {
        return activated === true && devices && devices.length ? 'Sí' : 'No';
      },
      width: 100
    },
    {
      Header: literal('general.actions'),
      id: 'actions',
      accessor: 'id',
      width: 100,
      canSortBy: false,
      Cell: props =>
        AudioSpookRowActions(props, value => {
          toggleOpen();
          setInstance(value);
        })
    }
  ];

  return (
    <CrudList
      title={literal('simso.title')}
      endpoint={PATHs.audioSpooks}
      columns={columns}
      usePagination
      canCreate={imAdmin && AudioSpookDetailUri}
      deleteModal={{
        isOpen,
        clickDelete: audiospooksAPI.deleteAudiospook,
        toggle: toggleOpen,
        instance,
        type: 'simso'
      }}
    />
  );
};

export default AudiospookList;
