import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { literal, setAdminToken, history, storage } from './index';

const notify = (
  message,
  autoClose = 4000,
  position = toast.POSITION.TOP_RIGHT
) =>
  toast.error(message, {
    position,
    autoClose
  });

const success = (message = literal('general.success'), ...options) => {
  return toast.success(message, { ...options, autoClose: 2000 });
};

const updating = (...options) => {
  const message = 'Update in progress, please wait...';
  return toast(message, { ...options, autoClose: false });
};

const info = ({ message, type = 'info', options = {} }) => {
  return toast[type](message, { ...options });
};

const endSupplant = options => {
  const adminToast = storage.getItem('adminToast');
  if (!adminToast) {
    const toastId = toast.info(literal('user.endSupplant'), {
      ...options,
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
      className: 'text-center',
      onClick: () => {
        setAdminToken();
        storage.deleteItem('adminToast');
        history.push('/users');

        window.location.reload();
      },
      closeButton: false
    });
    storage.setItem('adminToast', toastId);

    return toastId;
  }
};

export default { toast, notify, updating, info, endSupplant, success };
