import * as localization from '../../assets/localization/';
import * as get from 'lodash.get';
import { usersAPI } from '../requests';
import storage from './storage';

const defaultLang = 'es';
const locale = () => {
  if (storage.getPersistentItem('simso-language'))
    return storage.getPersistentItem('simso-language');
  else {
    const value = navigator.language.split('-')[0] || defaultLang;
    storage.setPersistentItem('simso-language', value);
    return value;
  }
};

const setLocale = lang => {
  storage.setPersistentItem('simso-language', lang);
  const user = storage.getPersistentItem('user');
  if (user) {
    usersAPI
      .patchAttributes(user.id, { language: lang })
      .then(() => {
        console.log('okey');
        window.location.reload();
      })
      .catch(() => {
        console.log('ko');
        window.location.reload();
      });
  } else {
    window.location.reload();
  }
};

const literalsProvider = () => {
  let current = locale();
  const available = ['es', 'en', 'ca'];
  if (available.indexOf(current) === -1) current = defaultLang;
  return localization[current];
};

const literal = (key, val) => {
  let value = get(literalsProvider(), key);
  if (val) value = value.replace('@', val);
  return value || key;
};

export { literal, setLocale, locale };
