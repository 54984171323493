import React from 'react';
import { Col } from 'reactstrap';
import styles from './MediaRow.module.scss';
import { literal } from '../../../../core/utils';
import * as moment from 'moment';
import { app } from '../../../../config';

const CalibrationRow = props => {
  const { calibration, range, responsible, createdAt } = props.data;
  const { email, name, lastName } = responsible;
  const text = name ? `${name} ${lastName || ''}` : email;
  let rangeName;
  const rangeFound = app.RANGES.find(({ value }) => value === range);
  if (rangeFound) rangeName = rangeFound.name;

  return (
    <Col className={`${styles.mediaCol}`} md={12}>
      <div className={`${styles.mediaContainer} pt-3 pb-3`}>
        <div className={`${styles.dataContainer} ${styles.mobileColumn}`}>
          <span className="mr-3">
            {literal('simso.media.created')}:{' '}
            <b>{moment(createdAt).format('DD-MM-YYYY HH:mm:ss')}</b>
          </span>
          <span className="mr-3">
            {literal('simso.calibration')}: <b>{calibration}</b>
          </span>
          <span className="mr-3">
            {literal('simso.range')}: <b>{literal(rangeName)}</b>
          </span>
          <span>
            {literal('simso.responsibleCalibration')}: <b>{text}</b>
          </span>
        </div>
      </div>
    </Col>
  );
};

export default CalibrationRow;
