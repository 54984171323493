import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { app } from '../../../config';
import styles from './Project.module.scss';
import Avatar from '../../Common/Avatar';
import { history, literal, toastUtils, storage } from '../../../core/utils';
import Detail from '../../Common/Detail';
import MapUpsert from '../../Common/MapUpsert';
import Loading from '../../Common/Loading';
import { projectsAPI } from '../../../core/requests';
import { ProjectDetailUri } from '../../../routes';
import DetailForm from '../../Common/DetailForm/DetailForm';

const ProjectInfo = props => {
  const { data, onCreate } = props;

  const [image, setImage] = useState();
  const [canSubmit, setCanSubmit] = useState();
  const [project, setProject] = useState();
  const [simsoLocations, setSimsoLocations] = useState();

  if (data) {
    const user = storage.getPersistentItem('user') || {};
    const imAdmin = user.role && user.role.name === 'ADMIN';

    const {
      id,
      name,
      location,
      users,
      audiospooks,
      morningInit,
      morningEnd,
      morningDBMax,
      middayInit,
      middayEnd,
      middayDBMax,
      afternoonInit,
      afternoonEnd,
      afternoonDBMax
    } = data;
    const description = data.description || '';
    const note = data.note || '';
    const { MORNING, MIDDAY, AFTERNOON } = app.DEFAULT_SCHEDULES;
    const { DEFAULT_MAX_LIMIT } = app;

    if (id) {
      if (!image && !!data.image) setImage(data.image);
      if (typeof canSubmit !== 'boolean') setCanSubmit(!!name && !!name.length);
      if (!simsoLocations && audiospooks && audiospooks.length) {
        setSimsoLocations(
          audiospooks
            .filter(simso => !!simso.locationCenter)
            .map(({ locationCenter, name }) => ({
              ...locationCenter,
              name
            }))
        );
      }
    }

    if (!project) setProject({ name, description, note, location });

    const setName = value => {
      project.name = value;
      const hasName = !!project.name && !!project.name.length;
      const rerender = canSubmit !== hasName;
      if (rerender) {
        setProject({ ...project });
        setCanSubmit(hasName);
      }
    };

    const setAttribute = (value, attribute) => {
      project[attribute] = value;
    };

    const submitForm = () => {
      const { id } = props.data;

      const data = { ...project, id, image };

      const toastId = toastUtils.updating();
      setCanSubmit(false);

      projectsAPI
        .updateProject(data)
        .then(projectCreated => {
          if (projectCreated) {
            setCanSubmit(true);
            toastUtils.toast.update(toastId, {
              type: toastUtils.toast.TYPE.SUCCESS,
              autoClose: 5000,
              render: literal('project.successUpsert')
            });

            if (!id) {
              onCreate && onCreate(projectCreated);
              history.push(ProjectDetailUri(projectCreated.id));
            }
          } else {
            toastUtils.toast.dismiss(toastId);
          }
        })
        .catch(err => {
          if (err) {
            toastUtils.toast.update(toastId, {
              type: toastUtils.toast.TYPE.ERROR,
              autoClose: 5000,
              render: literal('project.errorUpsert')
            });
          }
        });
    };

    return (
      <Row className={`mt-3 ${styles.projectContainer}`}>
        <Col sm={3} md={4} xl={3}>
          <Row>
            <Col md={12}>
              <Avatar
                alt="imagen"
                src={image}
                onLoaded={setImage}
                editable={!!imAdmin}
                xl={!imAdmin}
                name={'project'}
              />
            </Col>
            {!!id && id > 0 && (
              <Col md={12} className="text-center mt-3">
                <p>
                  <b>{literal('user.title')}: </b>
                  {(users && users.length) || 0}
                </p>
                <p>
                  <b>{literal('simso.title')}: </b>
                  {(audiospooks && audiospooks.length) || 0}
                </p>
              </Col>
            )}
          </Row>
        </Col>
        <Col sm={9} md={8} xl={9}>
          <Row>
            <Detail
              inputData={{
                type: 'text',
                label: literal('project.name'),
                placeholder: literal('project.phName'),
                name: 'name',
                value: name,
                editable: !!imAdmin
              }}
              onChange={setName}
            />

            <Detail
              inputData={{
                type: 'textarea',
                label: literal('project.description'),
                placeholder: literal('project.phDescription'),
                name: 'description',
                value: description,
                editable: !!imAdmin
              }}
              onChange={e => setAttribute(e, 'description')}
            />

            <Detail
              inputData={{
                type: 'textarea',
                label: literal('project.notes'),
                placeholder: literal('project.phNotes'),
                name: 'note',
                value: note,
                editable: !!imAdmin
              }}
              onChange={e => setAttribute(e, 'note')}
            />

            <DetailForm
              inputData={{
                label: literal('project.morning'),
                editable: !!imAdmin
              }}
              inputs={[
                {
                  type: 'time',
                  placeholder: literal('project.init'),
                  name: 'morningInit',
                  value: morningInit || MORNING.init
                },
                {
                  type: 'time',
                  placeholder: literal('project.end'),
                  name: 'morningEnd',
                  value: morningEnd || MORNING.end
                },
                {
                  type: 'text',
                  placeholder: literal('project.max'),
                  name: 'morningDBMax',
                  value: morningDBMax || DEFAULT_MAX_LIMIT
                }
              ]}
              onChange={e => {
                Object.keys(e).forEach(key => {
                  setAttribute(e[key], key);
                });
              }}
            />
            <DetailForm
              inputData={{
                label: literal('project.midday'),
                editable: !!imAdmin
              }}
              inputs={[
                {
                  type: 'time',
                  placeholder: literal('project.init'),
                  name: 'middayInit',
                  value: middayInit || MIDDAY.init
                },
                {
                  type: 'time',
                  placeholder: literal('project.end'),
                  name: 'middayEnd',
                  value: middayEnd || MIDDAY.end
                },
                {
                  type: 'text',
                  placeholder: literal('project.max'),
                  name: 'middayDBMax',
                  value: middayDBMax || DEFAULT_MAX_LIMIT
                }
              ]}
              onChange={e => {
                Object.keys(e).forEach(key => {
                  setAttribute(e[key], key);
                });
              }}
            />
            <DetailForm
              inputData={{
                label: literal('project.afternoon'),
                editable: !!imAdmin
              }}
              inputs={[
                {
                  type: 'time',
                  placeholder: literal('project.init'),
                  name: 'afternoonInit',
                  value: afternoonInit || AFTERNOON.init
                },
                {
                  type: 'time',
                  placeholder: literal('project.end'),
                  name: 'afternoonEnd',
                  value: afternoonEnd || AFTERNOON.end
                },
                {
                  type: 'text',
                  placeholder: literal('project.max'),
                  name: 'afternoonDBMax',
                  value: afternoonDBMax || DEFAULT_MAX_LIMIT
                }
              ]}
              onChange={e => {
                Object.keys(e).forEach(key => {
                  setAttribute(e[key], key);
                });
              }}
            />

            <MapUpsert
              editable={imAdmin}
              location={location}
              markers={simsoLocations}
              onChange={e => setAttribute(e, 'location')}
            />
          </Row>

          {imAdmin && (
            <Button
              color="primary"
              disabled={!canSubmit}
              onClick={submitForm}
              className="float-right"
            >
              {literal('project.save')}
            </Button>
          )}
        </Col>
      </Row>
    );
  } else return <Loading />;
};

export default ProjectInfo;
