import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import CrudList from '../../Common/ListItems/CrudList';
import { PATHs, audiospooksAPI, deviceApi } from '../../../core/requests';
import { literal } from '../../../core/utils';
import ControlCenterActions from './ControlCenterActions';

const ControlCenterList = () => {
  const [openUpdateVersionModal, setOpenUpdateVersionModal] = useState(false);
  const [openUpdateVersionAllModal, setOpenUpdateVersionAllModal] = useState(false);
  const [simsoId, setSimsoId] = useState();
  const [version, setVersion] = useState('');

  const columns = [
    {
      Header: literal('simso.uuid'),
      id: 'uuid',
      accessor: 'uuid',
      width: 300
    },
    {
      Header: literal('general.name'),
      id: 'name',
      accessor: 'name',
      width: 300
    },
    {
      Header: literal('project.single'),
      id: 'project.name',
      accessor: ({ project }) => {
        let value;
        if (project) {
          const { deletedAt, name } = project;
          if (deletedAt) value = `${name} (Eliminado)`;
          else value = name;
        } else value = literal('simso.noProject');
        return value;
      },
      width: 300,
      canSortBy: false
    },
    {
      Header: literal('device.list.columns.unreadNotifications'),
      id: 'devices.unreadNotifications',
      width: 200,
      accessor: ({ devices }) => devices.reduce((prev, curr) => prev + curr.unreadNotifications, 0)
    },
    {
      Header: literal('device.list.columns.appVersion'),
      id: 'devices.appVersion',
      width: 100,
      accessor: ({ devices }) => devices.at(0) ? devices.at(0).appVersion : '-'
    },
    {
      Header: literal('simso.activated'),
      id: 'activated',
      accessor: ({ activated, devices }) => {
        return activated === true && devices && devices.length ? 'Sí' : 'No';
      },
      width: 100
    },
    {
      Header: literal('general.actions'),
      id: 'actions',
      accessor: 'id',
      width: 100,
      canSortBy: false,
      Cell: props => ControlCenterActions(props, openUpdateSimsoModal)
    }
  ];

  const openUpdateSimsoModal = useCallback((simsoId) => {
    setSimsoId(simsoId);
    setOpenUpdateVersionModal(true);
  }, []);

  const tableActions = useMemo(() => [
    {
      text: literal('device.updateAll.button'),
      onClick: (instance) => setOpenUpdateVersionAllModal(true)
    }
  ], []);

  useEffect(() => {
    const fetchAppVersion = async () => {
      const { tag } = await deviceApi.getLatestVersion();
      setVersion(tag);
    };

    fetchAppVersion();
  }, []);

  return (
    <>
      <CrudList
        title={literal('device.title')}
        endpoint={PATHs.audioSpooks}
        columns={columns}
        usePagination
        tableActions={tableActions}
      />
      {openUpdateVersionModal && simsoId &&
        <Modal isOpen={openUpdateVersionModal}>
          <ModalHeader>{literal('device.update.title')}</ModalHeader>
          <ModalBody>
            <Row>
              <Col className="p-3" md={12}>
                {literal('device.update.message') + ' ' + version}
              </Col>
            </Row>
            <Row>
              <Col className="p-3" md={12}>
                {literal('device.update.message2')}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={() => setOpenUpdateVersionModal(false)}>
              {literal('general.close')}
            </Button>
            <Button color="primary" onClick={async () => {
              await audiospooksAPI.updateAudiospookVersion(simsoId);
              setOpenUpdateVersionModal(false);
            }}>
              {literal('device.update.button')}
            </Button>
          </ModalFooter>
        </Modal>
      }
      {openUpdateVersionAllModal &&
        <Modal isOpen={openUpdateVersionAllModal}>
          <ModalHeader>{literal('device.updateAll.title')}</ModalHeader>
          <ModalBody>
          <Row>
              <Col className="p-3" md={12}>
                {literal('device.updateAll.message') + ' ' + version}
              </Col>
            </Row>
            <Row>
              <Col className="p-3" md={12}>
                {literal('device.updateAll.message2')}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={() => setOpenUpdateVersionAllModal(false)}>
              {literal('general.close')}
            </Button>
            <Button color="primary" onClick={async () => {
              await audiospooksAPI.updateAudiospookVersionAll();
              setOpenUpdateVersionAllModal(false);
            }}>
              {literal('device.updateAll.button')}
            </Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default ControlCenterList;
