import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Button } from 'reactstrap';
import Detail from '../../Common/Detail';
import * as moment from 'moment';
import { literal } from '../../../core/utils';
import AudiospookHistoryGraph from './Graph/AudiospookHistoryGraph';
import styles from './Audiospook.module.scss';
import { audiospooksAPI } from '../../../core/requests';
import { Loading } from '../../Common';

function AudiospookHistoryData(props) {
  const { id } = props;

  const [audioData, setAudioData] = useState();
  const [initDate, setInitDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [duration, setDuration] = useState(moment.duration(10, 'minutes').as('hours'));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.forced) {
      const { duration: selectedDuration } = props.forced;
      const newInitDate = moment().add(moment.duration(-selectedDuration, 'hours'));
      setInitDate(newInitDate.format('YYYY-MM-DD HH:mm'));
      setDuration(selectedDuration);
      setAudioData(null);
      audiospooksAPI
        .getAudioHistoryData(id, {
          initDate: newInitDate.utc().format('YYYY-MM-DD HH:mm'),
          duration: selectedDuration,
        })
        .then(audio => {
          props.setForced(null);
          setAudioData(audio);
        });
    }
  }, [props.forced]);

  const calculateDuration = value => {
    setDuration(value);
  };

  const calculateInitDate = date => {
    setInitDate(moment(date).format('YYYY-MM-DD HH:mm'));
  };

  const submitForm = () => {
    if (id && id > 0) {
      setLoading(true);
      setAudioData(null);
      audiospooksAPI
        .getAudioHistoryData(id, {
          initDate: moment(initDate).utc().format('YYYY-MM-DD HH:mm'),
          duration,
        })
        .then(audio => {
          setLoading(false);
          setAudioData(audio);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      <Row className="mb-3 p-3">
        <Col md={6}>
          <Row>
            <Detail
              inputData={{
                type: 'date',
                label: literal('simso.date'),
                name: 'date',
                value: initDate,
                placeholder: literal('simso.date'),
              }}
              onChange={calculateInitDate}
            />
            <Detail
              inputData={{
                type: 'duration',
                value: duration,
                label: literal('simso.duration'),
              }}
              onChange={calculateDuration}
            />
            <Col>
              <Button color="primary" onClick={submitForm} className="float-right">
                {literal('simso.view')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="d-flex justify-content-center">
          {audioData && (
            <div className={styles.chartContainer}>
              <div className={styles.scrollableChart}>
                <AudiospookHistoryGraph data={audioData} />
              </div>
            </div>
          )}
          {loading ? <Loading></Loading> : null}
        </Col>
      </Row>
    </Fragment>
  );
}

export default AudiospookHistoryData;
