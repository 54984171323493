import React from 'react';
import { Spinner } from 'reactstrap';
import styles from './Loading.module.css';

const Loading = ({ type = 'grow' }) => {
  return (
    <div className={styles.container}>
      <Spinner className={styles.loader} type={type} color="primary" />
    </div>
  );
};

export default Loading;
