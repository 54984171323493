import { methods, PATHs } from '..';
import { request } from '../apiClient';

function updateAudiospook(data) {
  const { audiospook, audioSpooks } = PATHs;
  const uri = data.id ? audiospook(data.id) : audioSpooks;
  return request(uri, methods.PATCH, data).catch(err => {
    throw err;
  });
}

export { updateAudiospook };
