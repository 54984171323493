import { crudRequest } from '../apiClient';
import { methods, PATHs } from '..';
import { parseError } from '../../utils';

const getProject = id => {
  const { projectDetail } = PATHs;

  const params = {
    filter: {
      fields: [
        'id',
        'name',
        'email',
        'image',
        'description',
        'location',
        'morningInit',
        'morningEnd',
        'morningDBMax',
        'middayInit',
        'middayEnd',
        'middayDBMax',
        'afternoonInit',
        'afternoonEnd',
        'afternoonDBMax'
      ],
      include: [
        { relation: 'audiospooks' },
        {
          relation: 'users',
          scope: { fields: ['name', 'lastName', 'image', 'email'] }
        }
      ]
    }
  };
  const uri = projectDetail(id);

  return crudRequest({ uri, method: methods.GET, params }).catch(err => {
    parseError({ err, noFetch: 'crud.project.detail.error' });
    throw err;
  });
};

export { getProject };
