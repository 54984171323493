import { validJson } from './validation';
let persistentStorage;
const memoryStorage = {};

function persistentStorageSystem() {
  setPersistentStorageSystem(localStorage);
  if (persistentStorage) return persistentStorage;
  else if (sessionStorage.length) {
    setPersistentStorageSystem(sessionStorage);
  } else {
    setPersistentStorageSystem(localStorage);
  }
  return persistentStorage;
}

function setPersistentStorageSystem(system) {
  if (system === localStorage || system === sessionStorage)
    persistentStorage = system;
}

function setItem(key, value) {
  memoryStorage[key] = value;
}

function getItem(key) {
  return memoryStorage[key];
}

function deleteItem(key) {
  delete memoryStorage[key];
}

function setPersistentItem(key, value) {
  value = !!value && typeof value === 'object' ? JSON.stringify(value) : value;
  persistentStorageSystem().setItem(key, value);
}

function getPersistentItem(key) {
  let value = persistentStorageSystem().getItem(key);
  return !!value && validJson(value) ? JSON.parse(value) : value;
}

function deletePersistentItem(key) {
  persistentStorageSystem().removeItem(key);
}

function clearAllData() {
  localStorage.clear();
  sessionStorage.clear();
  Object.keys(memoryStorage).forEach(key => delete memoryStorage[key]);
}

const storage = {
  setPersistentItem,
  getPersistentItem,
  deletePersistentItem,
  setItem,
  getItem,
  deleteItem,
  clearAllData,
  setPersistentStorageSystem
};

export default storage;
