import { methods, PATHs } from '..';
import { crudRequest } from '../apiClient';
import { parseError } from '../../utils';

function getAudiospook(id) {
  const { audiospook } = PATHs;

  const params = {
    filter: {
      include: [
        { relation: 'project', scope: { fields: ['id', 'name'] } },
        {
          relation: 'devices',
          scope: { fields: ['id'], where: { enabled: 1 } }
        },
        {
          relation: 'medias',
          scope: { order: 'createdAt DESC' }
        },
        {
          relation: 'calibrations',
          scope: { order: 'createdAt DESC', include: 'responsible' }
        }
      ]
    }
  };
  const uri = audiospook(id);

  return crudRequest({ uri, method: methods.GET, params }).catch(err => {
    parseError({ err, noFetch: 'crud.audiospook.detail.error' });
    throw err;
  });
}

export { getAudiospook };
