import React, { useState, Fragment } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { audiospooksAPI } from '../../../../../core/requests';
import { literal, toastUtils } from '../../../../../core/utils';
import Detail from '../../../../Common/Detail';

const ModalActivate = props => {
  const {
    id,
    onSave,
    activated = false,
    hasDevices = false,
    imAdmin = false,
    credentials = {}
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [uuid, setUuid] = useState(credentials.uuid);
  const [plainPass, setPlainPass] = useState(credentials.plainPass);

  const clickActivate = doActivate => {
    if (imAdmin || doActivate) {
      const params = {
        credentials: { uuid, password: plainPass },
        activated: !activated
      };

      audiospooksAPI
        .activateAudiospook(id, params)
        .then(res => {
          toastUtils.success(
            literal(`simso.${activated ? 'in' : ''}activateSuccess`)
          );
          setIsOpen(false);
          onSave && onSave(!activated);
        })
        .catch(err => {
          toastUtils.notify(literal('simso.activateError'));
        });
    } else setIsOpen(true);
  };

  return (
    <Fragment>
      <div>
        <p>
          <Button
            color={activated ? 'secondary' : 'primary'}
            disabled={!hasDevices}
            onClick={() => clickActivate(false)}
          >
            {literal(
              `simso.${activated && hasDevices ? 'inactive' : 'active'}`
            )}
          </Button>
        </p>
        {!hasDevices && <small>{literal('simso.mustActive')}</small>}
      </div>

      <Modal isOpen={isOpen} size={'lg'}>
        <ModalHeader>{literal('simso.activeLong')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="p-3" md={12}>
              <Detail
                inputData={{
                  type: 'text',
                  label: literal('simso.UUID'),
                  name: 'uuid',
                  value: uuid,
                  placeholder: literal('simso.phUuid')
                }}
                onChange={setUuid}
              />

              <Detail
                inputData={{
                  type: 'text',
                  label: literal('simso.password'),
                  name: 'plainPass',
                  value: plainPass,
                  placeholder: literal('simso.phPassword')
                }}
                onChange={setPlainPass}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => setIsOpen(false)}>
            {literal('general.close')}
          </Button>
          <Button color="primary" onClick={() => clickActivate(true)}>
            {literal('simso.active')}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ModalActivate;
