import React, { useState } from 'react';

import { UserDetailUri } from '../../../routes';
import UserRowActions from './UserRowActions';
import CrudList from '../../Common/ListItems/CrudList';
import ColItemId from '../../Common/ListItems/Columns/ColItemId/ColItemId';
import { PATHs, usersAPI } from '../../../core/requests';
import { literal } from '../../../core/utils';
import { app } from '../../../config';

const ProjectsList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [instance, setInstance] = useState();
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const { ROLES } = app;

  const columns = [
    {
      Header: '#',
      accessor: 'id',
      id: 'id',
      width: 60,
      Cell: props => <ColItemId {...props} uriGenerator={UserDetailUri} />
    },
    {
      Header: literal('general.name'),
      accessor: 'name',
      id: 'name',
      minWidth: 200
    },
    {
      Header: literal('user.email'),
      id: 'email',
      accessor: 'email',
      minWidth: 200
    },
    {
      Header: literal('user.role'),
      id: 'roleId',
      accessor: row => {
        let value = '';
        const { name } = row.role;
        if (name) {
          value = ROLES.find(role => role.name === name);
          value = value ? value.label : name;
        }
        return value;
      },
      width: 150
    },
    {
      Header: literal('general.actions'),
      id: 'actions',
      accessor: 'id',
      maxWidth: 120,
      canSortBy: false,
      Cell: props =>
        UserRowActions(props, value => {
          toggleOpen();
          setInstance(value);
        })
    }
  ];

  return (
    <CrudList
      title={literal('user.title')}
      endpoint={PATHs.usersList}
      columns={columns}
      usePagination
      canCreate={UserDetailUri}
      deleteModal={{
        isOpen,
        clickDelete: usersAPI.deleteUser,
        toggle: toggleOpen,
        instance,
        type: 'user'
      }}
    />
  );
};

export default ProjectsList;
