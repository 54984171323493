const app = {
  INTEGRATION_TIME: {
    FIVE_SEC: '5 segundos',
    ONE_MIN: '1 minuto',
    ONE_HOUR: '1 hora'
  },
  ROLES: [
    { label: 'Administrador', name: 'ADMIN' },
    { label: 'Técnico', name: 'USER' },
    { label: 'Cliente - Administrador', name: 'CLIENT_ADMIN' },
    { label: 'Cliente - Usuario', name: 'CLIENT_USER' }
  ],
  DEFAULT_SCHEDULES: {
    MORNING: { init: '07:00', end: '19:00' },
    MIDDAY: { init: '19:00', end: '23:00' },
    AFTERNOON: { init: '23:00', end: '07:00' }
  },
  DEFAULT_MAX_LIMIT: 120,
  AUTO_RECORDING_TYPES: ['dBZ', 'dBA'],
  RANGES: [
    { value: 'standard', name: 'general.intensity.standard' },
    { value: 'low', name: 'general.intensity.low' }
  ],
  AUDIO_DATA: {
    DURATION: [
      { value: 1, name: '1 h' },
      { value: 2, name: '2 h' },
      { value: 4, name: '4 h' },
      { value: 8, name: '8 h' },
      { value: 16, name: '16 h' },
      { value: 24, name: '24 h' },
      { value: 168, name: '1 semana' }
    ],
    INTEGRATION: [
      { value: '5 seg', name: '5 seg' },
      { value: '1 min', name: '1 min' },
      { value: '1 h', name: '1 h' },
      { value: 'Ld', name: 'Ld' },
      { value: 'Le', name: 'Le' },
      { value: 'Ln', name: 'Ln' },
      { value: 'Lden', name: 'Lden' }
    ]
  }
};

export { app };
