import { request } from '../apiClient';
import { methods, PATHs } from '..';

function removeDevices(id) {
  const uri = PATHs.removeDevices(id);

  return request(uri, methods.DELETE).catch(err => {
    return Promise.reject(err);
  });
}

export { removeDevices };
