import React, { useState, useEffect } from 'react';

import Tabs from '../../Common/tabs/Tabs';
import UserInfo from './UserInfo';
import ElementsList from '../../Common/ElementsList';
import { literal } from '../../../core/utils';
import { usersAPI } from '../../../core/requests';

const User = props => {
  const { id } = props.match.params;
  const [data, setData] = useState();
  const modalTitle = `${literal('general.manage')} ${literal('project.title')}`;

  useEffect(() => {
    if (id && id > 0)
      usersAPI
        .getUser(id)
        .then(setData)
        .catch(() => setData({}));
    else setData({});
  }, []);

  return (
    <Tabs
      title={
        id && id > 0
          ? data && (data.name || data.email)
          : literal('user.create')
      }
      initialIndex="1"
      items={[
        {
          name: literal('user.info'),
          component: <UserInfo data={data} onCreate={setData} />
        },
        {
          name: literal('user.projects'),
          component: (
            <ElementsList
              elements={data}
              type={'projects'}
              onSave={() => {
                usersAPI.getUser(id).then(setData);
              }}
              modalRelation={{
                id: data && data.id,
                model: data && data.projects,
                title: modalTitle,
                isMulti: data && isMulti(data)
              }}
            />
          ),
          disabled: !data || !data.id
        }
      ]}
    />
  );
};

function isMulti(user = {}) {
  const rolesSingle = ['CLIENT_USER'];
  return user.role && rolesSingle.indexOf(user.role.name) === -1;
}

export default User;
