import toastUtils from './ToastUtils';
import { literal } from './localize';

const parseError = props => {
  const { err, message, noFetch } = props;
  let warned = false;
  if (err && err.message) {
    let error = message || literal(`general.errors.fetching`);
    switch (err.message) {
      case 'Failed to fetch': {
        if (noFetch) error = literal(noFetch);
        toastUtils.notify(error, 5000);
        warned = true;
        break;
      }
    }
  }
  return warned;
};

export { parseError };
