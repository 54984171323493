import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import styles from './Audiospook.module.scss';
import Loading from '../../Common/Loading/Loading';
import { storage } from '../../../core/utils';

import { AvatarButtons, GeneralData, DeviceConfig } from './Info';

function AudiospookInfo(props) {
  let { data, onSave } = props;
  const [audiospook, setAudiospook] = useState();

  useEffect(() => {
    if (audiospook && !audiospook.oldCalibration && audiospook.calibration) {
      if (audiospook.calibrations) {
        audiospook.calibrations.forEach(calibration => {
          if (!calibration.range) calibration.range = audiospook.range;
        });
      }

      setAudiospook({
        ...audiospook,
        oldCalibration: audiospook.calibration,
        oldRange: audiospook.range,
      });
    }
  }, [audiospook]);
  if (data) {
    const user = storage.getPersistentItem('user') || {};
    const imAdmin = user.role && user.role.name === 'ADMIN';

    if (!audiospook) setAudiospook(data);

    return (
      <Fragment>
        <Row className={`mt-3 ${styles.audiospookContainer}`}>
          <Col sm={3} md={4} xl={3}>
            <AvatarButtons
              simso={audiospook}
              setSimso={setAudiospook}
              onSave={onSave}
              editable={!!imAdmin}
            />
          </Col>
          <Col sm={9} md={8} xl={9}>
            <GeneralData
              simso={audiospook}
              setSimso={setAudiospook}
              onSave={onSave}
              editable={!!imAdmin}
            ></GeneralData>
            {audiospook && audiospook.id ? (
              <DeviceConfig
                simso={audiospook}
                setSimso={setAudiospook}
                onSave={onSave}
                editable={!!imAdmin}
              ></DeviceConfig>
            ) : null}
          </Col>
        </Row>
      </Fragment>
    );
  } else return <Loading />;
}

export default AudiospookInfo;
