import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useTableState } from 'react-table';
import Table from './Table';
import { usersAPI, projectsAPI, audiospooksAPI } from '../../../core/requests';
import { PATHs } from '../../../core/requests';
import {
  Button,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { literal, toastUtils } from '../../../core/utils';

function CrudList({
  columns,
  endpoint,
  canCreate = false,
  deleteModal = {},
  title,
  tableActions,
  ...props
}) {
  const { usePagination } = props;
  const { isOpen, clickDelete, toggle, type, instance } = deleteModal;

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const initialState = { pageSize: 20, pageIndex: 0, filters: {} };
  const state = useTableState(initialState);
  const [{ sortBy, filters, pageIndex, pageSize }, setState] = state;
  const currentRequestRef = useRef();

  const fetchData = () => {
    setLoading(true);
    const id = Date.now();
    currentRequestRef.current = id;

    return getData(
      endpoint,
      pageIndex,
      state,
      setState,
      setData,
      usePagination,
      currentRequestRef,
      id,
      setCount
    )
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        if (err && err.message) {
          let error;
          switch (err.message) {
            default: {
              error = literal(`crud.${type}.list.error`);
            }
          }
          toastUtils.notify(error, 5000);
        }
        setLoading(false);
      });
  };

  const onClickDeleteModal = () => {
    clickDelete &&
      clickDelete(instance)
        .then(count => {
          if (count) {
            toggle(isOpen);
            fetchData();
            toastUtils.success(literal(`crud.${type}.delete.success`));
          }
        })
        .catch(err => {
          if (err) toastUtils.notify(literal(`crud.${type}.delete.error`));
        });
  };

  // When sorting, filters, pageSize, or pageIndex change, fetch new data
  useEffect(() => {
    firstTime ? setFirstTime(false) : fetchData();
  }, [sortBy, filters, pageIndex, pageSize]);

  return (
    <Fragment>
      <Table
        {...{
          endpoint,
          data,
          columns,
          canCreate,
          title,
          state,
          loading,
          count,
          manualSorting: true,
          manualFilters: true,
          manualPagination: true,
          disableMultiSort: true,
          disableGrouping: true,
          disablePageResetOnDataChange: true,
          tableActions
        }}
      />

      {!!deleteModal && !!isOpen && (
        <Modal isOpen={true}>
          <ModalHeader>{literal(`crud.${type}.delete.title`)}</ModalHeader>
          <ModalBody>
            <Row>
              <Col className="p-3" md={12}>
                {literal(`crud.${type}.delete.message`)}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={() => toggle(isOpen)}>
              {literal('general.close')}
            </Button>
            <Button color="primary" onClick={onClickDeleteModal}>
              {literal('general.delete')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
}

const getData = (
  endpoint,
  pageIndex,
  state,
  setState,
  setData,
  usePagination,
  currentRequestRef,
  id,
  setCount
) => {
  if (usePagination && pageIndex === 0) {
    return getCountData(endpoint, {
      filters: state[0].filters && state[0].filters
    })
      .then(data => {
        if (data) {
          const count = data.count;
          const pageSize = state
            ? state[0].pageSize
              ? state[0].pageSize
              : 0
            : 0;
          const pageCount = Math.ceil(count / pageSize);
          setCount(count);

          if (currentRequestRef.current !== id) {
            return;
          }

          setState(old => ({
            ...old,
            pageCount
          }));
        } else throw null;
      })
      .then(() => {
        const params = usePagination
          ? {
              filters: state[0].filters,
              page: state[0].pageIndex,
              size: state[0].pageSize,
              orderBy: state[0].sortBy
            }
          : {
              filters: state[0].filters,
              orderBy: state[0].sortBy
            };

        return getRowsData(endpoint, params).then(rows => {
          setData(rows);
          setState(old => ({
            ...old
          }));
        });
      })
      .catch(err => {
        throw err;
      });
  } else {
    const params = usePagination
      ? {
          filters: state[0].filters,
          page: state[0].pageIndex,
          size: state[0].pageSize
        }
      : {
          filters: state[0].filters
        };

    return getRowsData(endpoint, params).then(rows => {
      setData(rows);
    });
  }
};

const getCountData = (endpoint, data) => {
  data = { ...data, crud: true };
  if (endpoint === PATHs.audioSpooks) {
    return audiospooksAPI.getAudioSpooksCount(data);
  } else if (endpoint === PATHs.projects) {
    return projectsAPI.getProjectsCount(data);
  } else if (endpoint === PATHs.usersList) {
    return usersAPI.getUsersCount(data);
  } else {
    return Promise.resolve(true);
  }
};

const getRowsData = (endpoint, data) => {
  data = { ...data, crud: true };
  if (endpoint === PATHs.audioSpooks) {
    return audiospooksAPI.getAudioSpooks(data);
  } else if (endpoint === PATHs.projects) {
    return projectsAPI.getProjects(data);
  } else if (endpoint === PATHs.usersList) {
    return usersAPI.getUsers(data);
  } else {
    return Promise.resolve(true);
  }
};
export default CrudList;
